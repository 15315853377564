<script
    lang="ts"
    setup
>
    type Props = {
        pending?: boolean
    }

    type Emit = {
        (event: 'cancel'): void
        (event: 'confirm'): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const { t } = useLang()
    const { maxTablet } = useWindowSize()
    const confirmContext = useConfirmContext()

    const isComposableCall = !!confirmContext.value.callback

    const cancel = () => {
        if (isComposableCall) {
            confirmContext.value.callback(false)

            clearContext()
        } else {
            emit('cancel')
        }
    }

    const confirm = () => {
        if (isComposableCall) {
            confirmContext.value.callback(true)

            clearContext()
        } else {
            emit('confirm')
        }
    }

    const clearContext = (): void => {
        confirmContext.value.callback = undefined
        confirmContext.value.confirmText = undefined
        confirmContext.value.titleText = undefined
        confirmContext.value.bodyText = undefined
        confirmContext.value.cancelText = undefined
        confirmContext.value.confirmButtonArgs = undefined
        confirmContext.value.cancelButtonArgs = undefined
    }

    const titleText = computed<string>(() => {
        let text = t('are-you-sure') + '?'

        if (isComposableCall && confirmContext.value.titleText) {
            text = confirmContext.value.titleText
        }

        return text
    })

    const bodyText = computed<string>(() => {
        let text

        if (isComposableCall && confirmContext.value.bodyText) {
            text = confirmContext.value.bodyText
        }

        return text
    })

    const confirmText = computed<string>(() => {
        let text = t('confirm')

        if (isComposableCall && confirmContext.value.confirmText) {
            text = confirmContext.value.confirmText
        }

        return text
    })

    const cancelText = computed<string>(() => {
        let text = t('cancel')

        if (isComposableCall && confirmContext.value.cancelText) {
            text = confirmContext.value.cancelText
        }

        return text
    })
</script>

<template>
    <AppModal
        :version-bottom="maxTablet"
        impulsive-transition
        @close="cancel()"
    >
        <template
            v-if="$slots['icon']"
            #icon
        >
            <slot name="icon" />
        </template>

        <template #title>
            <slot name="title">
                <div class="flex text-center">
                    {{ titleText }}
                </div>
            </slot>
        </template>

        <div
            v-if="$slots['body'] || bodyText"
            key="body"
            class="mb-6 text-[14px] leading-[130%] text-center text-[#8a8f9e]"
        >
            <slot name="body">
                {{ bodyText }}
            </slot>
        </div>

        <div class="flex gap-4 w-full mt-2">
            <slot
                name="cancel"
                :cancel="cancel"
            >
                <AppButton
                    secondary
                    v-bind="confirmContext.cancelButtonArgs"
                    :disabled="props.pending"
                    class="flex-1"
                    @click="cancel()"
                >
                    <slot name="cancel-text">
                        {{ cancelText }}
                    </slot>
                </AppButton>
            </slot>

            <slot
                name="confirm"
                :confirm="confirm"
            >
                <AppButton
                    v-bind="confirmContext.confirmButtonArgs"
                    :loading="props.pending"
                    class="flex-1"
                    @click="confirm()"
                >
                    <slot name="confirm-text">
                        {{ confirmText }}
                    </slot>
                </AppButton>
            </slot>
        </div>
    </AppModal>
</template>
