export enum ChatKindEnum {
    New = 'new',
    My = 'my',
    Archive = 'archive',
}

export enum ChatStatusEnum {
    New = 1,
    Active = 2,
    Closed = 3,
    Missed = 4,
    Rejected = 5,
}

export enum ChatMessageKindEnum {
    Chat = 'chat',
    Note = 'note',
}

export enum ChatMessageTypeEnum {
    Text = 1,
    Image = 2,
    Article = 3,
    Log = 4,
    Gif = 5,
}

export enum ChatMessageTypeLogTypeEnum {
    Accept = 1,
    Close = 2,
    Reopen = 3,
    Decline = 4,
    Transfer = 5,
}

export enum ChatMemberUserTypeEnum {
    Operator = 1,
    Visitor = 2,
}

export enum ChatMessageTextPartEnum {
    Text = 'text',
    Link = 'link',
    Emoji = 'emoji',
    LineBreak = 'line-break',
}
