<script
    lang="ts"
    setup
>
    import { useNotificationStore } from '~/stores/notification'

    const notificationStore = useNotificationStore()

    onMounted(() => notificationStore.processCookieNotifications())
</script>

<template>
    <Teleport to="body">
        <TransitionGroup
            name="snackbar-notification-transition"
            tag="div"
            :class="$style['snackbar']"
        >
            <TheSnackbarNotification
                v-for="notification in notificationStore.notifications"
                :key="notification.id"
                :notification="notification"
                class="origin-bottom"
            />
        </TransitionGroup>
    </Teleport>
</template>

<style
    lang="sass"
    module
>
    .snackbar
        z-index: 100
        pointer-events: none
        overflow: hidden
        position: fixed
        display: flex
        flex-direction: column
        align-items: center
        bottom: 20px
        width: 100%
        will-change: max-height, transform
</style>

<style lang="sass">
    .snackbar-notification-transition
        &-enter-from,
        &-leave-to
            max-height: 0
            transform: scale(0)

        &-enter-active
            pointer-events: none
            transition: max-height 200ms var(--transition-default-ease), transform 200ms var(--transition-default-ease)

        &-leave-active
            pointer-events: none
            transition: max-height 120ms var(--transition-default-ease), transform 120ms var(--transition-default-ease)

        &-enter-to,
        &-leave-from
            max-height: 56px
            transform: scale(1)
</style>
