import type { FetchContext } from 'ofetch'

export default (error: FetchContext['error'], response: FetchContext['response']): void => {
    const errorName: string | undefined = error?.name
    const errorStatusCode: number | undefined = response?.status || error?.statusCode

    if (errorStatusCode === 422) {
        return
    }

    if (errorName === 'AbortError') {
        return
    }

    if (import.meta.client && !useTabActivity().isTabActive.value) {
        return
    }

    const handleableErrorStatuses = [ 403, 404, 429 ]

    let tag: string
    let text: string
    let timeout: number
    let closeOnRouteChange: boolean

    if (isNetworkError(error)) {
        tag = text = 'fetch-error-internet'
        timeout = 0
        closeOnRouteChange = true
    } else if (handleableErrorStatuses.includes(errorStatusCode)) {
        tag = 'fetch-error-' + errorStatusCode
        text = `error-${ errorStatusCode }-description`
    } else {
        tag = 'fetch-error-500'
        text = 'request-failed'
        timeout = 0
        closeOnRouteChange = true
    }

    useNotify().push({
        type: 'error',
        tag,
        text: useLang().t(text),
        afterPageReload: import.meta.server,
        timeout,
        closeOnRouteChange,
    })
}
