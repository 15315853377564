import * as Sentry from '@sentry/vue'
import { useUserStore } from '~/stores/user'
import pluginCheckContext from '~/helpers/pluginCheckContext'

export default defineNuxtPlugin((nuxtApp) => {
    const userStore = useUserStore()

    if (useError().value || !pluginCheckContext(nuxtApp.$router as any, userStore)) {
        return
    }

    const { sentry, environment } = nuxtApp.$config.public

    if (!sentry.dsn) {
        return
    }

    // const router = useRouter()

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: sentry.dsn,
        environment: environment + ':client',
        // integrations: [
        //     new Sentry.BrowserTracing({
        //         routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        //     }),
        //     new Sentry.Replay()
        // ],
        // trackComponents: true,
        // hooks: [ 'activate', 'create', 'destroy', 'mount', 'update' ],
        // TODO відкорегувати для прод версії
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        // tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: [ 'localhost', apiUrl ],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        // replaysSessionSampleRate: 0.1,
        // replaysOnErrorSampleRate: 1.0
    })

    nuxtApp.vueApp.config.errorHandler = (error, context) => {
        Sentry.withScope((scope) => {
            scope.setExtra('context', context)
            Sentry.captureException(error)
        })
    }

    nuxtApp.hook('app:error', (error) => {
        Sentry.captureException(error)
    })

    const setUser = async (): Promise<void> => {
        const getIP = async (): Promise<string> => {
            try {
                return (await $fetch<{ ip: string }>('https://api.ipify.org?format=json')).ip
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (error) {
                return ''
            }
        }

        Sentry.setUser({
            id: userStore.user.id,
            email: userStore.user.email,
            ip_address: await getIP(),
        })
    }

    if (userStore.isAuthenticated) {
        setUser()
    } else {
        userStore.onIn(setUser)
    }
})
