import type { $Fetch, NitroFetchRequest } from 'nitropack'
import type * as userTypes from '~/ts/types/user'

export default <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
    one({ id, expand }: userTypes.UserOne) {
        return fetch<userTypes.User>('/user/{userId}', {
            params: {
                id,
                expand: getFilledKeysString(expand),
            },
        })
    },
    update({ data }: userTypes.UserUpdate) {
        return fetch<userTypes.User>('/user/{userId}', {
            method: 'put',
            body: data,
        })
    },
    updatePhoto({ data }: userTypes.UserUpdatePhoto) {
        return fetch<{ id: any, file: userTypes.User['photo'] }>('/user/{userId}/photo', {
            method: 'post',
            body: data,
        })
    },
    deletePhoto() {
        return fetch('/user/{userId}/photo', {
            method: 'delete',
        })
    },
    emailChangeRequest({ data }: userTypes.UserEmailChangeRequest) {
        return fetch<userTypes.User['changeEmailRequest']>('/user/{userId}/change-email-request', {
            method: 'post',
            body: data,
        })
    },
    emailChangeRequestRepeat({ requestId }: userTypes.UserEmailChangeRequestRepeat) {
        return fetch(`/user/{userId}/change-email-request/${ requestId }`, {
            method: 'post',
        })
    },
    emailChangeRequestDelete({ requestId }: userTypes.UserEmailChangeRequestDelete) {
        return fetch(`/user/{userId}/change-email-request/${ requestId }`, {
            method: 'delete',
        })
    },
    passwordChangeRequest() {
        return fetch('/user/{userId}/change-password', {
            method: 'post',
        })
    },
})
