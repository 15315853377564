import type { WSMessage } from '~/ts/types/ws'

export default (message: string): WSMessage => {
    const value = JSON.parse(message)

    return {
        action: value[0],
        channel: value[1],
        event: value[2],
        payload: value[3],
    }
}
