import { defineStore } from 'pinia'
import type * as chatTypes from '~/ts/types/chat'
import type { Operator } from '~/ts/types/operator'
import type { CChannel } from '~/ts/types/communication-channel'
import type { QuickAnswer, QuickAnswerTag } from '~/ts/types/quick-answer'
import type { KBItem } from '~/ts/types/knowledge-base'
import type { BroadcastManager } from '~/helpers/chat/BroadcastManager'
import type { ChatKindEnum } from '~/ts/enums/chat'
import { ChatStatusEnum } from '~/ts/enums/chat'
import { useUserStore } from '~/stores/user'
import chatStoreGetters from '~/helpers/chat/chatStoreGetters'
import chatStoreActions from '~/helpers/chat/chatStoreActions'
import wsEventHandler from '~/helpers/chat/wsEventHandler'

export const useChatStore = defineStore('chatStore', () => {
    // Внутрішні змінні
    const userStore = useUserStore()
    const bus = useEventBus()
    const lang = useLang()
    const windowSize = useWindowSize()
    const tab = useTabActivity()
    const { currentRoute } = useRouter()

    const broadcastManager = shallowRef<BroadcastManager | undefined>()
    const refreshChatsOnTabActive = ref<boolean>(false)
    const chatRouteChangedHook = createEventHook<number>()
    const chatTransferCanceledHook = createEventHook<number>()

    // Змінні для експорту
    const chats = ref<chatTypes.Chat[]>([])
    const quickAnswerTags = shallowRef<QuickAnswerTag[]>([])
    const quickAnswers = shallowRef<QuickAnswer[]>([])
    const knowledgeBaseItems = shallowRef<KBItem[]>([])
    const operators = shallowRef<Operator[]>([])
    const gifs = shallowRef<chatTypes.ChatGif[]>([])
    const communicationChannels = shallowRef<CChannel[]>([])
    const currentKind = ref<ChatKindEnum>(currentRoute.value.params.kind as ChatKindEnum)
    const currentCid = ref<number>(+currentRoute.value.params.cid)
    const chatListRefreshKey = ref<number>(0)
    const cidPageRefreshKey = ref<number>(0)
    const chatTypingContexts = ref<Record<chatTypes.Chat['id'], chatTypes.ChatTypingContext>>({})
    const archiveChatListFilters = ref<chatTypes.ChatArchiveFilters>({
        dateFrom: undefined,
        dateTo: undefined,
        status: ChatStatusEnum.Closed,
        channels: [],
        operators: [],
    })
    const archiveChatsFiltering = ref<boolean>(false)
    const archiveChatsLoading = ref<boolean>(false)
    const archiveChatsPage = ref<number>(1)
    const noMoreArchiveChats = ref<boolean>(false)

    const {
        newChatList,
        myChatList,
        archiveChatList,
        currentChatList,
        currentChatSortedList,
        chatIds,
        chatsById,
        currentChat,
        operatorOptions,
        numberOfUnreadMessagesInMyChats,
        numberOfUnreadMessagesInNewChats,
        isCurrentChatNew,
        isCurrentChatClosed,
        isCurrentChatInTransferProcess,
        isThereChatWithUnreadMessagesInOtherProjectsByProjectId,
        isThereChatWithUnreadMessages,
    } = chatStoreGetters({
        userStore,
        chats,
        operators,
        currentCid,
        currentKind,
    })

    const {
        init,
        fillQuickAnswers,
        fillKnowledgeBase,
        fillGifs,
        addChats,
        updateChat,
        refreshChats,
        removeChats,
        handleTransferredChats,
        refreshArchivedChats,
        getChatKind,
        startChatConnection,
        ensureCurrentCid,
        removeCurrentCid,
        sendMessage,
        sendTyping,
        insertMessagesIntoChat,
        submitEditableMessage,
        updateChatMessage,
        removeChatMessage,
        buildChatListQuery,
        isChatRoute,
        handleHookNotification,
    } = chatStoreActions({
        userStore,
        bus,
        lang,
        tab,
        windowSize,
        broadcastManager,
        refreshChatsOnTabActive,
        chats,
        currentCid,
        currentKind,
        currentRoute,
        currentChat,
        chatIds,
        chatsById,
        myChatList,
        newChatList,
        archiveChatList,
        archiveChatsPage,
        archiveChatListFilters,
        noMoreArchiveChats,
        cidPageRefreshKey,
        chatListRefreshKey,
        chatTypingContexts,
        operators,
        gifs,
        communicationChannels,
        knowledgeBaseItems,
        quickAnswers,
        quickAnswerTags,
        chatRouteChangedHook,
        chatTransferCanceledHook,
    })

    watch(currentRoute, (route) => {
        if (!isChatRoute(route)) {
            return
        }

        const { kind, cid } = route.params

        currentKind.value = kind as ChatKindEnum
        currentCid.value = +cid

        chatRouteChangedHook.trigger(currentCid.value)
    })

    tab.onTabActive(() => {
        if (refreshChatsOnTabActive.value) {
            refreshChats()
        }
    })

    wsEventHandler({
        userStore,
        broadcastManager,
        lang,
        chats,
        chatIds,
        chatsById,
        currentCid,
        cidPageRefreshKey,
        chatRouteChangedHook,
        chatTransferCanceledHook,
        startChatConnection,
        handleHookNotification,
        addChats,
        updateChat,
        refreshChats,
        removeChats,
        handleTransferredChats,
        getChatKind,
        ensureCurrentCid,
        removeCurrentCid,
    })

    return {
        chats,
        quickAnswerTags,
        quickAnswers,
        knowledgeBaseItems,
        operators,
        gifs,
        communicationChannels,
        currentKind,
        currentCid,
        chatListRefreshKey,
        cidPageRefreshKey,
        chatTypingContexts,
        archiveChatListFilters,
        archiveChatsFiltering,
        archiveChatsLoading,
        archiveChatsPage,
        noMoreArchiveChats,

        newChatList,
        myChatList,
        archiveChatList,
        currentChatList,
        currentChatSortedList,
        chatIds,
        currentChat,
        isCurrentChatNew,
        isCurrentChatClosed,
        isCurrentChatInTransferProcess,
        operatorOptions,
        numberOfUnreadMessagesInNewChats,
        numberOfUnreadMessagesInMyChats,
        isThereChatWithUnreadMessages,
        isThereChatWithUnreadMessagesInOtherProjectsByProjectId,

        fillQuickAnswers,
        fillKnowledgeBase,
        fillGifs,
        ensureCurrentCid,
        removeCurrentCid,
        sendMessage,
        sendTyping,
        insertMessagesIntoChat,
        refreshArchivedChats,
        getChatKind,
        init,
        buildChatListQuery,
        addChats,
        removeChats,
        updateChat,
        submitEditableMessage,
        updateChatMessage,
        removeChatMessage,
        handleTransferredChats,
    }
})
