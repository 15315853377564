import pluginCheckContext from '~/helpers/pluginCheckContext'
import type { PopupManager as PopupManagerType } from '~/helpers/PopupManager'
import { PopupManager } from '~/helpers/PopupManager'

export type Injections = {
    popupManager: PopupManagerType
}

declare module '#app' {
    interface NuxtApp {
        $popupManager: Injections['popupManager']
    }
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $popupManager: Injections['popupManager']
    }
}

export default defineNuxtPlugin((nuxtApp): { provide: Injections } => {
    const popupManager = new PopupManager()

    if (useError().value || !pluginCheckContext(nuxtApp.$router as any)) {
        return { provide: { popupManager } }
    }

    const { document } = getClientContext()

    const onKeyup = (event): void => {
        if (
            event.shiftKey
            || event.metaKey
            || event.ctrlKey
            || event.altKey
        ) {
            return
        }

        if (event.code === 'Escape') {
            popupManager.closeLastMenuOrModal()
        }
    }

    onEvent(document, 'keyup', onKeyup, {
        capture: true,
        passive: true,
    })

    return { provide: { popupManager } }
})
