import type * as chatTypes from '~/ts/types/chat'
import { ChatKindEnum, ChatStatusEnum } from '~/ts/enums/chat'
import { BroadcastManager } from '~/helpers/chat/BroadcastManager'
import getChatTitle from '~/helpers/getChatTitle'
import getOperatorName from '~/helpers/getOperatorName'

export default (context: chatTypes.ChatWsEventHandlerContext) => {
    if (!import.meta.client) {
        return
    }

    const {
        userStore,
        broadcastManager,
        lang,
        chats,
        chatIds,
        chatsById,
        currentCid,
        cidPageRefreshKey,
        chatRouteChangedHook,
        chatTransferCanceledHook,
        startChatConnection,
        handleHookNotification,
        addChats,
        updateChat,
        refreshChats,
        removeChats,
        handleTransferredChats,
        getChatKind,
        ensureCurrentCid,
        removeCurrentCid,
    } = context

    const notify = useNotify()

    broadcastManager.value = new BroadcastManager()

    broadcastManager.value.silentMode.value = !userStore.isOperatorOnline

    watch(() => userStore.isOperatorOnline, (value) => {
        broadcastManager.value.silentMode.value = !value
    })

    const {
        channel: projectChatsChannel,
        events: {
            onNewChat,
            onChatAccepted,
            onNewTransferredChat,
            onChatRejected,
            onChatFinished,
        },
    } = broadcastManager.value.addProjectChatsChannel(userStore.currentProject.id)

    onNewChat((chat: chatTypes.Chat, done) => {
        addChats([ chat ])

        done(handleHookNotification)
    })

    onNewTransferredChat((chat: chatTypes.Chat, done) => {
        if (userStore.currentOperator.id !== chat.chatTransfer?.toOperator?.id) {
            // Якщо це поточний користувач в іншій вкладці
            if (userStore.currentOperator.id === chat.chatTransfer.fromOperator?.id) {
                updateChat(chat.id, chat, true)
            }

            return
        }

        addChats([ chat ])

        nextTick(handleTransferredChats)

        const notifyId = 'chat-transfer:' + chat.id

        const cleanUp = (): void => {
            notify.remove(notifyId)

            offChatRouteChangedHook()
            offChatTransferCanceledHook()
        }

        const offChatRouteChangedHook = chatRouteChangedHook.on((cid) => {
            if (cid === chat.id) {
                cleanUp()
            }
        })

        const offChatTransferCanceledHook = chatTransferCanceledHook.on((cid) => {
            if (cid === chat.id) {
                cleanUp()
            }
        })

        const milliseconds = Math.floor(
            dateUtil
                .rawFromSQL(chat.chatTransfer.expired_at, { zone: 'utc' })
                .diffNow('millisecond')
                .milliseconds,
        )

        notify.push({
            id: notifyId,
            type: 'custom',
            text: lang.t('chat-transfer-notify', getOperatorName(chat.chatTransfer.fromOperator), getChatTitle(chat)),
            timeout: milliseconds,
            pauseOnHover: false,
            closeOnClick: false,
            customTextColor: '#fff',
            customBgColor: '#1a1a1a',
            customIconComponentName: 'AppIconOperatorSwitch',
            actionText: useLang().t('view'),
            actionTextInactiveColor: '#aaadb8',
            onAction() {
                cleanUp()

                if (chatsById.value[chat.id]) {
                    navigateTo({
                        name: 'p-pid-chat-kind-cid',
                        params: {
                            kind: getChatKind(chat),
                            cid: chat.id,
                        },
                    })
                }
            },
            onClose: cleanUp,
        })

        done(handleHookNotification)
    })

    onChatAccepted(async (chat: chatTypes.Chat, done) => {
        if (userStore.currentOperator.id === chat.operator_id) {
            updateChat(chat.id, chat, true)

            if (currentCid.value !== chat.id) {
                await navigateTo({
                    name: 'p-pid-chat-kind-cid',
                    params: {
                        kind: getChatKind(chat),
                        cid: chat.id,
                    },
                })
            }
        } else {
            await removeChats([ chat ])
        }

        done()
    })

    onChatRejected(async (chat: chatTypes.Chat, done) => {
        chatTransferCanceledHook.trigger(chat.id)

        // Відмова від нового чату
        if (chat.status === ChatStatusEnum.Rejected) {
            updateChat(chat.id, chat)

            if (currentCid.value === chat.id) {
                await removeCurrentCid()
                await ensureCurrentCid(ChatKindEnum.New)
            }

            return done()
        }

        // Відмова від передачі отримувачем
        if (chat.operator_id === userStore.currentOperator.id) {
            updateChat(chat.id, chat)

            cidPageRefreshKey.value++

            return done()
        }

        /* Скасування передачі ініціатором */

        removeChats([ chat ])

        if (currentCid.value === chat.id) {
            await removeCurrentCid()
            await ensureCurrentCid(ChatKindEnum.New)
        }

        done()
    })

    onChatFinished(async (chat: chatTypes.Chat, done) => {
        if (chatIds.value.includes(chat.id)) {
            updateChat(chat.id, chat)

            if (currentCid.value === chat.id) {
                await removeCurrentCid()
                await ensureCurrentCid(ChatKindEnum.New)
            }
        } else {
            addChats([ chat ])
        }

        done()
    })

    broadcastManager.value.broadcaster.onConnected(() => {
        projectChatsChannel.subscribe()

        if (broadcastManager.value.broadcaster.wasDisconnected.value) {
            refreshChats()
        } else {
            // Початкова ініціалізація
            chats.value.forEach(startChatConnection)
        }
    })

    broadcastManager.value.broadcaster.onDisconnected(() => {
        projectChatsChannel.unsubscribe()

        chatIds.value.forEach((id) => {
            broadcastManager.value.getChatChannel(id)?.unsubscribe()
            broadcastManager.value.getChatClientChannel(id)?.unsubscribe()
        })
    })
}
