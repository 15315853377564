import type { NitroFetchRequest, $Fetch } from 'nitropack'
import type { User } from '~/ts/types/user'
import type { Project } from '~/ts/types/project'
import type * as authTypes from '~/ts/types/auth'

export default <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
    handshake({ preventLogout = false, expand }: authTypes.AuthHandshake) {
        return fetch<authTypes.AuthHandshakeReturnValue>('/auth/info', {
            preventLogout,
            params: {
                expand: getFilledKeysString(expand),
            },
        })
    },
    login({ email, password }: authTypes.AuthLogin) {
        return fetch<{ access_token: string, refresh_token: string }>('/auth/signin', {
            skipAuthTokenProcessing: true,
            method: 'post',
            body: {
                login: email,
                password,
            },
        })
    },
    register({ email, expand }: authTypes.AuthRegister) {
        return fetch<{ user: User, access_token: string, refresh_token: string }>('/auth/signup', {
            skipAuthTokenProcessing: true,
            method: 'post',
            params: { expand: getFilledKeysString(expand) },
            body: { email },
        })
    },
    googleAuth({ token }: authTypes.AuthGoogleAuth) {
        return fetch<{ access_token: string, refresh_token: string }>('/auth/google', {
            skipAuthTokenProcessing: true,
            method: 'post',
            body: { token },
        })
    },
    resendConfirmEmail() {
        return fetch('/auth/resend-confirm-email', {
            method: 'post',
        })
    },
    passwordResetRequest({ email }: authTypes.AuthPasswordResetRequest) {
        return fetch('/auth/password-reset-request', {
            skipAuthTokenProcessing: true,
            method: 'post',
            body: { email },
        })
    },
    resetPassword({ password, token }: authTypes.AuthResetPassword) {
        return fetch('/auth/password-reset', {
            skipAuthTokenProcessing: true,
            method: 'post',
            params: { token },
            body: { password },
        })
    },
    verifyNewEmail({ token }: authTypes.AuthVerifyNewEmail) {
        return fetch('/auth/verify-new-email', {
            skipAuthTokenProcessing: true,
            method: 'post',
            params: { token },
        })
    },
    refreshToken({ refreshToken }: authTypes.AuthRefreshToken) {
        return fetch<{ access_token: string, refresh_token: string }>('/auth/refresh-token', {
            skipAuthTokenProcessing: true,
            method: 'post',
            body: { token: refreshToken },
        })
    },
    confirmInvitation({ token }: authTypes.AuthConfirmInvitation) {
        return fetch<{
            user?: User
            project?: Project
            access_token?: string
            refresh_token?: string
        }>('/auth/invite', {
            skipAuthTokenProcessing: true,
            method: 'post',
            params: { token },
        })
    },
    wsAuth() {
        return fetch<{ access_token: string }>('/auth/socket', {
            method: 'post',
        })
    },
})
