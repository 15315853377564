import type { $Fetch, NitroFetchRequest } from 'nitropack'
import type * as visitorTypes from '~/ts/types/visitor'

export default <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
    update({ id, data }: visitorTypes.VisitorUpdate) {
        return fetch<visitorTypes.Visitor>(`/project/{projectId}/visitor/${ id }`, {
            method: 'put',
            body: data,
        })
    },
    createContact({ visitorId, data }: visitorTypes.VisitorCreateContact) {
        return fetch<visitorTypes.VisitorContact>(`/project/{projectId}/visitor/${ visitorId }/contact `, {
            method: 'post',
            body: data,
        })
    },
    updateContact({ visitorId, id, data }: visitorTypes.VisitorUpdateContact) {
        return fetch<visitorTypes.VisitorContact>(`/project/{projectId}/visitor/${ visitorId }/contact/${ id }`, {
            method: 'put',
            body: data,
        })
    },
    deleteContact({ visitorId, id }: visitorTypes.VisitorDeleteContact) {
        return fetch(`/project/{projectId}/visitor/${ visitorId }/contact/${ id }`, {
            method: 'delete',
        })
    },
})
