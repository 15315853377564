import { storeToRefs } from 'pinia'
import type { AppNotification } from '~/ts/types/app'
import { useNotificationStore } from '~/stores/notification'

export type UseNotifyPreset = 'request-failed' | 'changes-saved'
export type UseNotifyPresetOptions = Omit<Partial<AppNotification>, 'close'>

export const useNotify = () => {
    const notificationStore = useNotificationStore()
    const { notifications, availableNotificationTypes } = storeToRefs(notificationStore)

    const pushPreset = (preset: UseNotifyPreset, options?: UseNotifyPresetOptions): void => {
        const { t } = useLang()

        switch (preset) {
            case 'request-failed':
                notificationStore.pushNotification({
                    type: 'error',
                    text: t(preset),
                    timeout: 0,
                    ...options,
                })
                break
            case 'changes-saved':
                notificationStore.pushNotification({
                    type: 'success',
                    text: t(preset),
                    timeout: APP_NOTIFY_CHANGES_SAVED_TIMEOUT,
                    actionText: t('discard-changes'),
                    actionTextInactiveColor: '#208b27',
                    actionTextActiveColor: '#4da053',
                    ...options,
                })
                break
            default:
                throw new Error('Unexpected preset: ' + preset)
        }
    }

    return {
        notifications,
        availableTypes: availableNotificationTypes,
        push: notificationStore.pushNotification,
        remove: notificationStore.removeNotification,
        pushPreset,
    }
}
