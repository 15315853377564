import type { $Fetch, NitroFetchRequest } from 'nitropack'
import type { AppTablePagination } from '~/ts/types/app'
import type * as invoiceTypes from '~/ts/types/invoice'

export default <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
    all({ page = 1, perPage = undefined }: invoiceTypes.InvoiceAll = {}) {
        return fetch<{ items: invoiceTypes.Invoice[], _meta: AppTablePagination }>('/project/{projectId}/invoice', {
            params: {
                page,
                'per-page': perPage,
            },
        })
    },
    one({ id }: invoiceTypes.InvoiceOne) {
        return fetch<invoiceTypes.Invoice>(`/project/{projectId}/invoice/${ id }`)
    },
    payWithCard({ id, cardId }: invoiceTypes.InvoicePayWithCard) {
        return fetch<invoiceTypes.Invoice>(`/project/{projectId}/invoice/${ id }/pay`, {
            method: 'post',
            body: {
                user_card_id: cardId,
            },
        })
    },
    payWithWayForPay({ id }: invoiceTypes.InvoicePayWithWayForPay) {
        return fetch<{ url: string }>(`/project/{projectId}/invoice/${ id }/pay-direct`, {
            method: 'post',
        })
    },
})
