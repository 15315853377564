import type { $Fetch, NitroFetchRequest } from 'nitropack'
import type { AppItemTimezone } from '~/ts/types/app'

export default <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
    getTimezones() {
        return fetch<AppItemTimezone[]>('/setting/timezones')
    },
    getWidgetConfig() {
        return fetch<any>('/setting/widget-config')
    },
})
