import type { FormSelectOption } from '~/ts/types/form'
import type { Chat, ChatStoreGettersContext } from '~/ts/types/chat'
import { ChatKindEnum, ChatStatusEnum } from '~/ts/enums/chat'

export default (context: ChatStoreGettersContext) => {
    const {
        userStore,
        chats,
        operators,
        currentKind,
        currentCid,
    } = context

    const newChatList = computed<Chat[]>(() => {
        return chats.value.filter(v => v.status === ChatStatusEnum.New)
    })

    const myChatList = computed<Chat[]>(() => {
        return chats.value.filter(v => v.status === ChatStatusEnum.Active)
    })

    const archiveChatList = computed<Chat[]>(() => {
        return chats.value.filter(v => CHAT_ARCHIVE_STATUSES.includes(v.status))
    })

    const currentChatList = computed<Chat[]>(() => {
        switch (currentKind.value) {
            case ChatKindEnum.New:
                return newChatList.value
            case ChatKindEnum.My:
                return myChatList.value
            case ChatKindEnum.Archive:
                return archiveChatList.value
            default:
                return []
        }
    })

    const currentChatSortedList = computed<Chat[]>(() => {
        return currentChatList.value.sort((a, b) => {
            const lastMessageTimeA = a.messages.length ? a.messages.at(-1).created_at : 0
            const lastMessageTimeB = b.messages.length ? b.messages.at(-1).created_at : 0

            return (lastMessageTimeB - lastMessageTimeA) || (a.created_at - b.created_at)
        })
    })

    // TODO оптимізувати, на стороні серверу не кешується і викликається десятки разів https://github.com/nuxt/nuxt/issues/2447
    const chatsById = computed<Record<number, Chat>>(() => {
        return chats.value.reduce((result, chat) => {
            result[chat.id] = chat

            return result
        }, {})
    })

    const chatIds = computed<number[]>(() => chats.value.map(v => v.id))

    // TODO обробити кейс, якщо у урл буде обрано чат, який не буде на першій сторінці пагінації при оновленні сторінки
    const currentChat = computed<Chat | undefined>(() => {
        if (!currentCid.value) {
            return
        }

        return chatsById.value[currentCid.value]
    })

    const isCurrentChatNew = computed<boolean>(() => {
        return currentChat.value?.status === ChatStatusEnum.New
    })

    const isCurrentChatClosed = computed<boolean>(() => {
        return CHAT_ARCHIVE_STATUSES.includes(currentChat.value?.status)
    })

    const isCurrentChatInTransferProcess = computed<boolean>(() => {
        return !!currentChat.value?.chatTransfer
    })

    const operatorOptions = computed<FormSelectOption[]>(() => {
        const operatorOptions: FormSelectOption[] = []

        for (const index in operators.value) {
            const value = operators.value[index]

            if (
                (value.id === userStore.currentOperator.id)
                || !value.enabled
                || value.invited_at
            ) {
                continue
            }

            operatorOptions.push({
                _extra: {
                    searchValue: (value.user.name || '') + value.user.email,
                    ...value.user,
                },
                value: value.id,
                text: value.user.email,
            })
        }

        return operatorOptions
    })

    const numberOfUnreadMessagesInNewChats = computed<number>(() => {
        return newChatList.value.length
    })

    const numberOfUnreadMessagesInMyChats = computed<number>(() => {
        return myChatList.value.reduce((count, chat) => {
            if (chat.numberOfUnreadMessages) {
                count++
            }

            return count
        }, 0)
    })

    const isThereChatWithUnreadMessages = computed<boolean>(() => {
        return !!(
            numberOfUnreadMessagesInNewChats.value
            + numberOfUnreadMessagesInMyChats.value
        )
    })

    const isThereChatWithUnreadMessagesInOtherProjectsByProjectId = computed<Record<string, boolean>>(() => {
        return userStore.handshakeUnreadInOtherProjects.reduce((result, value) => {
            if (!result[value.project_id]) {
                result[value.project_id] = false
            }

            if (value.unread) {
                result[value.project_id] = true
            }

            return result
        }, {})
    })

    return {
        newChatList,
        myChatList,
        archiveChatList,
        currentChatList,
        currentChatSortedList,
        chatsById,
        chatIds,
        currentChat,
        isCurrentChatNew,
        isCurrentChatClosed,
        isCurrentChatInTransferProcess,
        operatorOptions,
        numberOfUnreadMessagesInNewChats,
        numberOfUnreadMessagesInMyChats,
        isThereChatWithUnreadMessages,
        isThereChatWithUnreadMessagesInOtherProjectsByProjectId,
    }
}
