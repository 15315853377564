import type { FetchContext } from 'ofetch'
import pushErrorNotify from '~/helpers/fetch/pushErrorNotify'

export default (fetchContext: FetchContext, place: string) => {
    const error = fetchContext.error || fetchContext.response._data

    if (isObject(error)) {
        error.extra = {
            request: fetchContext.request,
            options: fetchContext.options,
        }
    }

    pushErrorNotify(error, fetchContext.response)

    if (isObject(error)) {
        error.message = fetchContext.request + ': ' + error?.message || '-'
    }

    useLog(error, place, {
        error: true,
        devOnly: true,
    })
}
