import { useAppStore } from '~/stores/app'

export default defineNuxtPlugin({
    name: 'loader',
    hooks: {
        'app:created'(): void {
            if (useError().value) {
                return
            }

            const appStore = useAppStore()

            useTimer(() => {
                appStore.showLoader = false
            }, 300, { immediate: true })
        },
    },
})
