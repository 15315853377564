import type { DateTimeJSOptions, DateTimeOptions } from 'luxon/src/datetime'
import { DateTime } from 'luxon'
import { useUserStore } from '~/stores/user'

export const dateUtilConfig = {
    _userStore: undefined,
    _lang: undefined,
    getUserStore() {
        if (!this._userStore) {
            this._userStore = useUserStore()
        }

        return this._userStore
    },
    getLang() {
        if (!this._lang) {
            this._lang = useLang()
        }

        return this._lang
    },
    getTimezone(): string {
        return this.getUserStore().user.timezone
    },
    getLocale(): string {
        return this.getLang().locale.value.nativeLocale
    },
    applyOnlyTimezone(dt: DateTime): DateTime {
        return dt
            .setZone(this.getTimezone())
    },
    applyOnlyLocale(dt: DateTime): DateTime {
        return dt
            .setLocale(this.getLocale())
    },
    applyConfig(dt: DateTime): DateTime {
        return dt
            .setZone(this.getTimezone())
            .setLocale(this.getLocale())
    },
}

export const dateUtil = {
    weekdaysFull: [ 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday' ],
    rawNow() {
        return DateTime.now()
    },
    rawLocal() {
        return DateTime.local()
    },
    rawFromSQL(value: string, options?: DateTimeOptions) {
        return DateTime.fromSQL(value, options)
    },
    rawFromSeconds(value: number, options?: DateTimeJSOptions) {
        return DateTime.fromSeconds(value, options)
    },
    rawFromMillis(value: number, options?: DateTimeJSOptions) {
        return DateTime.fromMillis(value, options)
    },
    now() {
        return dateUtilConfig.applyConfig(DateTime.now())
    },
    local() {
        return dateUtilConfig.applyConfig(DateTime.local())
    },
    fromISO(value: string) {
        return dateUtilConfig.applyConfig(DateTime.fromISO(value))
    },
    fromSQL(value: string) {
        return dateUtilConfig.applyConfig(DateTime.fromSQL(value))
    },
    fromSeconds(value: number) {
        return dateUtilConfig.applyConfig(DateTime.fromSeconds(value))
    },
    fromMillis(value: number) {
        return dateUtilConfig.applyConfig(DateTime.fromMillis(value))
    },
    getDayStartAt(datetime: DateTime) {
        return datetime.startOf('day').toUnixInteger()
    },
    formatDateToString(datetime: DateTime, format?: string) {
        return datetime.toFormat(format || DATE_DISPLAY_DATE_FORMAT)
    },
    formatHumanDate(datetime: DateTime, options: {
        short?: boolean
        relativeCalendar?: boolean
    } = {}): string {
        const {
            short = false,
            relativeCalendar = false,
        } = options

        const diffInDays = datetime.diff(this.now(), 'day').days
        const lessThanOneWeek = (diffInDays < 0) && (diffInDays > -7)

        if (lessThanOneWeek) {
            const diffInMinutes = datetime.diff(this.now(), 'minute').minutes
            const lessThanOneMinute = (diffInMinutes < 0) && (diffInMinutes > -1)

            if (lessThanOneMinute) {
                return dateUtilConfig.getLang().t('just-now')
            }

            return stringUtil.upperFirst(datetime[relativeCalendar ? 'toRelativeCalendar' : 'toRelative']())
        }

        const diffInMonths = datetime.diff(this.now(), 'month').months
        const lessThanOneMonth = (diffInMonths < 0) && (diffInMonths > -1)

        if (lessThanOneMonth) {
            return datetime.toLocaleString({ month: 'long', day: '2-digit' })
        }

        if (short) {
            return datetime.toFormat(DATE_DISPLAY_DATE_FORMAT)
        }

        return datetime.toLocaleString(DateTime.DATE_FULL)
    },
}
