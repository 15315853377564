export const APP_REGISTRATION_STEP_ROUTE_NAMES: readonly string[] = Object.freeze([
    'language-auth-sign-up-step-1',
    'language-auth-sign-up-step-2',
    'language-auth-sign-up-step-3',
    'language-auth-sign-up-step-4',
])
export const APP_INACTIVE_OPERATOR_AVAILABLE_AUTH_ROUTE_NAMES: readonly string[] = Object.freeze([
    'p-pid-setting',
    'p-pid-setting-account',
    'p-pid-setting-project',
    'p-pid-setting-project-id-edit',
    'p-pid-setting-project-id-edit-logo',
    'p-pid-setting-project-id-edit-settings',
    'p-pid-setting-project-create',
])
export const APP_ROLE_DEPENDENT_ROOT_ROUTE_NAMES: readonly string[] = Object.freeze([
    'p-pid-setting-operator',
    'p-pid-setting-channel',
])
export const APP_FREEMIUM_OPERATOR_QUANTITY = 3
export const APP_DEFAULT_PER_PAGE = 5
export const APP_MAX_PER_PAGE = 999
export const APP_NOTIFY_CHANGES_SAVED_TIMEOUT = 10 * 1000
