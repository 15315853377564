import type { $Fetch, NitroFetchRequest } from 'nitropack'
import type { AppTablePagination } from '~/ts/types/app'
import type * as quickAnswerTypes from '~/ts/types/quick-answer'

export default <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
    all({ page = 1, perPage = APP_DEFAULT_PER_PAGE }: quickAnswerTypes.QuickAnswerAll = {}) {
        return fetch<{ items: quickAnswerTypes.QuickAnswer[], _meta: AppTablePagination }>('/project/{projectId}/message-template', {
            params: {
                page,
                'per-page': perPage,
            },
        })
    },
    one({ id }: quickAnswerTypes.QuickAnswerOne) {
        return fetch<quickAnswerTypes.QuickAnswer>(`/project/{projectId}/message-template/${ id }`, {
            params: { id },
        })
    },
    create({ data }: quickAnswerTypes.QuickAnswerCreate) {
        return fetch<quickAnswerTypes.QuickAnswer>('/project/{projectId}/message-template', {
            method: 'post',
            body: data,
        })
    },
    update({ id, data }: quickAnswerTypes.QuickAnswerUpdate) {
        return fetch<quickAnswerTypes.QuickAnswer>(`/project/{projectId}/message-template/${ id }`, {
            method: 'put',
            body: data,
        })
    },
    delete({ id }: quickAnswerTypes.QuickAnswerDelete) {
        return fetch(`/project/{projectId}/message-template/${ id }`, {
            method: 'delete',
        })
    },
    tagList() {
        return fetch<quickAnswerTypes.QuickAnswerTag[]>('/project/{projectId}/message-template/tags')
    },
})
