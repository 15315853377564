import type { UseLogOptions, UseLogExecutor } from '~/composables/useLog'

type LoggableOptions = {
    defaultLogOptions?: UseLogOptions
    disabled?: boolean
}

export class Loggable {
    private readonly logger = useLogLazy()

    constructor(
        private options: LoggableOptions = { defaultLogOptions: { hint: true } },
    ) {
    }

    protected log: UseLogExecutor = (message, place, options = this.options?.defaultLogOptions): void => {
        if (this.options?.disabled) {
            return
        }

        this.logger(message, place, options)
    }
}
