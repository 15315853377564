import type { RouteLocation } from 'vue-router'
import { useUserStore } from '~/stores/user'

export const gaPageView = (to: RouteLocation): void => {
    const { window, location } = getClientContext()
    const { appName, gaMeasurementId } = useRuntimeConfig().public
    const userStore = useUserStore()

    window.gtag('event', 'page_view', {
        app_name: appName,
        screen_name: to.name,
        page_path: to.path,
        page_location: location.href,
        page_title: getPageTechnicalName(to),
        user_id: userStore.user?.id,
        send_to: gaMeasurementId,
    })
}
