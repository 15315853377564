<script
    lang="ts"
    setup
>
    import type { AppNotification, AppNotificationAvailableIconNames as AvailableIconNames } from '~/ts/types/app'

    type Props = {
        notification: AppNotification
    }

    const SUCCESS_COLOR = '#208b27'
    const ERROR_COLOR = '#dd0e0e'
    const INFO_COLOR = '#000'
    const INFO_BG_COLOR = '#ebeef1'

    const props = defineProps<Props>()

    const { t } = useLang()

    const hasAction = computed<boolean>(() => !!props.notification.actionText)

    let timer: ReturnType<typeof useTimer>
    let actionTimer: ReturnType<typeof useTimer>

    const actionTimerSeconds = ref<number | undefined>(
        (hasAction.value && props.notification.timeout)
            ? Math.floor(props.notification.timeout / 1000)
            : undefined,
    )

    const actionTimerSecondsText = computed<string>(() => {
        if (isNumber(actionTimerSeconds.value)) {
            return ` (${ actionTimerSeconds.value + t('seconds-short') })`
        } else {
            return ''
        }
    })

    const availableIconComponents = computed<Record<AvailableIconNames, ReturnType<typeof defineComponent>>>(() => ({
        AppIconInfo: resolveComponent('AppIconInfo'),
        AppIconCheckBold: resolveComponent('AppIconCheckBold'),
        AppIconAlertCircle: resolveComponent('AppIconAlertCircle'),
        AppIconOperatorSwitch: resolveComponent('AppIconOperatorSwitch'),
    }))

    const textColor = computed<string>(() => {
        switch (props.notification.type) {
            case 'success':
                return SUCCESS_COLOR
            case 'error':
                return ERROR_COLOR
            case 'custom':
                return props.notification.customTextColor || INFO_COLOR
            default:
                return INFO_COLOR
        }
    })

    const bgColor = computed<string>(() => {
        switch (props.notification.type) {
            case 'success':
                return '#dff1d9'
            case 'error':
                return '#ffedf1'
            case 'custom':
                return props.notification.customBgColor || INFO_BG_COLOR
            default:
                return INFO_BG_COLOR
        }
    })

    const iconComponent = computed<ReturnType<typeof defineComponent>>(() => {
        switch (props.notification.type) {
            case 'success':
                return resolveComponent('AppIconCheckBold')
            case 'error':
                return resolveComponent('AppIconAlertCircle')
            case 'custom':
                return availableIconComponents.value[props.notification.customIconComponentName]
            default:
                return resolveComponent('AppIconInfo')
        }
    })

    const iconColor = computed<ReturnType<typeof defineComponent>>(() => {
        switch (props.notification.type) {
            case 'success':
                return SUCCESS_COLOR
            case 'error':
                return ERROR_COLOR
            case 'custom':
                return props.notification.customTextColor || INFO_COLOR
            default:
                return INFO_COLOR
        }
    })

    const contentStyle = computed<string>(() => {
        return `
            --snackbar-notification-text-color:${ textColor.value };
            --snackbar-notification-bg-color:${ bgColor.value };
        `
    })

    const onClick = (): void => {
        props.notification.onClick?.(props.notification)

        if (props.notification.closeOnClick) {
            props.notification.close()
        }
    }

    const onActionClick = (): void => {
        props.notification.onAction?.(props.notification)
    }

    const runActionTimer = (): void => {
        if (!actionTimerSeconds.value) {
            return
        }

        actionTimer = useTimer(
            () => {
                actionTimerSeconds.value--

                if (!actionTimerSeconds.value) {
                    actionTimer.stop()
                }
            },
            1000,
            {
                interval: true,
                immediate: true,
                callAtStart: true,
            },
        )
    }

    const runTimers = (): void => {
        if (!props.notification.timeout) {
            return
        }

        timer = useTimer(
            () => props.notification.close(),
            props.notification.timeout,
            { immediate: true },
        )

        runActionTimer()
    }

    const pauseTimers = (): void => {
        if (!props.notification.pauseOnHover) {
            return
        }

        timer?.pause()

        if (hasAction.value) {
            actionTimer?.pause()
        }
    }

    const resumeTimers = (): void => {
        if (!props.notification.pauseOnHover) {
            return
        }

        const remaining = timer?.start()

        if (hasAction.value) {
            actionTimer?.start()

            if (remaining) {
                actionTimerSeconds.value = Math.floor(remaining / 1000)
            }
        }
    }

    onMounted(() => {
        props.notification.onShow?.(props.notification)

        runTimers()
    })
</script>

<template>
    <div
        class="z-[1] pointer-events-auto overflow-hidden cursor-pointer"
        @mouseenter="pauseTimers"
        @mouseleave="resumeTimers"
        @click="onClick()"
    >
        <div
            :class="`
                flex
                items-center
                gap-2
                m-1
                px-4
                py-3
                text-[14px]
                font-medium
                leading-[130%]
                rounded-[8px]
                text-[var(--snackbar-notification-text-color)]
                bg-[var(--snackbar-notification-bg-color)]
            `"
            :style="contentStyle"
        >
            <component
                :is="iconComponent"
                key="icon"
                :color="iconColor"
                size="16"
            />

            <div key="text">
                {{ props.notification.text }}
            </div>

            <AppButton
                v-if="hasAction"
                key="action"
                underlined
                small
                :active-color="props.notification.actionTextActiveColor"
                :inactive-color="props.notification.actionTextInactiveColor"
                @click.stop="onActionClick()"
            >
                {{ props.notification.actionText }}{{ actionTimerSecondsText }}
            </AppButton>
        </div>
    </div>
</template>
