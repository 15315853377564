import type { $Fetch, NitroFetchRequest } from 'nitropack'
import type * as subscriptionTypes from '~/ts/types/subscription'

export default <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
    one({ id }: subscriptionTypes.SubscriptionOne) {
        return fetch<subscriptionTypes.Subscription>(`/project/{projectId}/subscription/${ id }`)
    },
    cancel({ id }: subscriptionTypes.SubscriptionCancel) {
        return fetch<subscriptionTypes.Subscription>(`/project/{projectId}/subscription/${ id }/cancel`, {
            method: 'post',
        })
    },
    renew({ id }: subscriptionTypes.SubscriptionRenew) {
        return fetch<subscriptionTypes.Subscription>(`/project/{projectId}/subscription/${ id }/renew`, {
            method: 'post',
        })
    },
})
