import type { WSMessage } from '~/ts/types/ws'

export default (message: WSMessage): string => {
    const value = [
        message.action,
        message.channel,
    ]

    if (message.event) {
        value.push(message.event)
    }

    if (!isEmpty(message.payload, { primitive: true })) {
        value.push(message.payload)
    }

    return JSON.stringify(value)
}
