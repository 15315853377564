import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src3920258916/src/spilky-client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src3920258916/src/spilky-client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src3920258916/src/spilky-client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/codebuild/output/src3920258916/src/spilky-client/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src3920258916/src/spilky-client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src3920258916/src/spilky-client/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src3920258916/src/spilky-client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_YdLad5Mpq3 from "/codebuild/output/src3920258916/src/spilky-client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src3920258916/src/spilky-client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src3920258916/src/spilky-client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _01_api_GIiciWaEWX from "/codebuild/output/src3920258916/src/spilky-client/plugins/01.api.ts";
import _03_localization_gPzlazUODu from "/codebuild/output/src3920258916/src/spilky-client/plugins/03.localization.ts";
import _04_setup_5l0XcBq6CK from "/codebuild/output/src3920258916/src/spilky-client/plugins/04.setup.ts";
import _05_popupManager_peaaqOqHaJ from "/codebuild/output/src3920258916/src/spilky-client/plugins/05.popupManager.ts";
import _06_sentry_client_GkpL1Rt3Wt from "/codebuild/output/src3920258916/src/spilky-client/plugins/06.sentry.client.ts";
import _07_handleChunkError_client_aezU7vHS9y from "/codebuild/output/src3920258916/src/spilky-client/plugins/07.handleChunkError.client.ts";
import _08_gtag_client_Zn9YTpSZV7 from "/codebuild/output/src3920258916/src/spilky-client/plugins/08.gtag.client.ts";
import _09_hideLoader_client_14W30P2Ib8 from "/codebuild/output/src3920258916/src/spilky-client/plugins/09.hideLoader.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  _01_api_GIiciWaEWX,
  _03_localization_gPzlazUODu,
  _04_setup_5l0XcBq6CK,
  _05_popupManager_peaaqOqHaJ,
  _06_sentry_client_GkpL1Rt3Wt,
  _07_handleChunkError_client_aezU7vHS9y,
  _08_gtag_client_Zn9YTpSZV7,
  _09_hideLoader_client_14W30P2Ib8
]