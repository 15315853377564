import type { AnyFn } from '~/ts/types/common'
import type { Chat, ChatMessage } from '~/ts/types/chat'
import type { Project } from '~/ts/types/project'
import type { EventHook } from '~/utils/createEventHook'
import type { BroadcasterChannel } from '~/helpers/ws/BroadcasterChannel'
import { WSChannelEventEnum, WSClientChannelEventEnum } from '~/ts/enums/ws'
import getChatTitle from '~/helpers/getChatTitle'
import getVisitorName from '~/helpers/getVisitorName'
import getOperatorName from '~/helpers/getOperatorName'

type ProjectChatsHook = 'onNewChat' | 'onChatAccepted' | 'onChatRejected' | 'onChatFinished' | 'onNewTransferredChat'
type ChatHook = 'onNewMessage' | 'onMessageRead'
type ChatClientHook = 'onTyping'
type Hooks = Record<ProjectChatsHook | ChatHook | ChatClientHook | string, EventHook>
type HookTrigger = (
    cb: (
        payload: Chat | any,
        done: VoidFunction
    ) => void
) => void
type HookTriggerWithNotification = (
    cb: (
        entity: Chat | ChatMessage,
        done: (
            getNotification?: (notification: Notification) => void
        ) => void
    ) => void
) => void

export type ProjectChatsChannelEvents = {
    onNewChat: HookTriggerWithNotification
    onNewTransferredChat: HookTriggerWithNotification
    onChatAccepted: HookTrigger
    onChatRejected: HookTrigger
    onChatFinished: HookTrigger
}

export type ChatChannelEvents = {
    onNewMessage: HookTriggerWithNotification
    onMessageUpdate: HookTrigger
    onMessageDelete: HookTrigger
    onMessageRead: HookTrigger
}

export type ChatClientChannelEvents = {
    onTyping: HookTrigger
}

export type ProjectChatsChannelContext = {
    channel: BroadcasterChannel
    events: ProjectChatsChannelEvents
    created: boolean
}

export type ChatChannelContext = {
    channel: BroadcasterChannel
    events: ChatChannelEvents
    created: boolean
}

export type ChatClientChannelContext = {
    channel: BroadcasterChannel
    events: ChatClientChannelEvents
    created: boolean
}

export class BroadcastManager {
    public readonly silentMode = ref<boolean>(false)
    public readonly broadcaster = useBroadcaster()

    private readonly hooks = new Map<string, Hooks>()
    private readonly router = useRouter()
    private readonly lang = useLang()
    private readonly isWindowFocused = useWindowFocus().isWindowFocused
    private readonly webNotify = useWebNotification()
    private readonly sounds = {
        newChat: useSound('new-chat'),
        newChatAccepted: useSound('new-chat-accepted'),
        newArchiveChat: useSound('new-archive-chat'),
        newTransferredChat: useSound('new-transferred-chat'),
        newMessage: useSound('new-message'),
    }

    public addProjectChatsChannel(projectId: Project['id']): ProjectChatsChannelContext {
        return this.addChannel<ProjectChatsChannelContext>(
            'projectChats.' + projectId,
            [
                'onNewChat',
                'onChatAccepted',
                'onChatRejected',
                'onChatFinished',
                'onNewTransferredChat',
            ],
            this.listenProjectChatsChannelEvents.bind(this),
        )
    }

    public addChatChannel(id: Chat['id']): ChatChannelContext {
        return this.addChannel<ChatChannelContext>(
            'chat.' + id,
            [
                'onNewMessage',
                'onMessageUpdate',
                'onMessageDelete',
                'onMessageRead',
            ],
            this.listenChatChannelEvents.bind(this),
        )
    }

    public addChatClientChannel(id: Chat['id']): ChatClientChannelContext {
        return this.addChannel<ChatClientChannelContext>(
            'chatClient.' + id,
            [ 'onTyping' ],
            this.listenChatClientChannelEvents.bind(this),
        )
    }

    public getChatChannel(id: Chat['id']): BroadcasterChannel | undefined {
        return this.broadcaster.channels.get('chat.' + id)
    }

    public getChatClientChannel(id: Chat['id']): BroadcasterChannel | undefined {
        return this.broadcaster.channels.get('chatClient.' + id)
    }

    public removeChatChannel(id: Chat['id'], remove = false): void {
        const channelName = 'chat.' + id

        if (!this.broadcaster.channels.has(channelName)) {
            return
        }

        this.hooks.delete(channelName)

        this.broadcaster.channels.get(channelName).unsubscribe(remove)
    }

    public removeChatClientChannel(id: Chat['id'], remove = false): void {
        const channelName = 'chatClient.' + id

        if (!this.broadcaster.channels.has(channelName)) {
            return
        }

        this.hooks.delete(channelName)

        this.broadcaster.channels.get(channelName).unsubscribe(remove)
    }

    public sendChatClientChannelMessage(id: Chat['id'], event: WSClientChannelEventEnum, payload?: any): void {
        const channelName = 'chatClient.' + id

        this.broadcaster.channels.get(channelName)?.send(event, payload)
    }

    private addChannel = <ReturnValue>(
        channelName: string,
        eventHooks: (ProjectChatsHook | ChatHook | string)[],
        listenMethod: AnyFn,
    ): ReturnValue => {
        const existingChannel = this.broadcaster.channels.get(channelName)

        const channelHooks = (() => {
            if (!existingChannel) {
                this.hooks.set(channelName, eventHooks.reduce((hooks, name) => {
                    hooks[name] = createEventHook()

                    return hooks
                }, {}))
            }

            return eventHooks.reduce((hooks, name) => {
                hooks[name] = this.hooks.get(channelName)[name]

                return hooks
            }, {})
        })()

        const returnValue = eventHooks.reduce((result, name) => {
            result.events[name] = channelHooks[name].on

            return result
        }, { channel: existingChannel, events: {}, created: !existingChannel }) as ReturnValue

        if (existingChannel) {
            return returnValue
        }

        const channel = this.broadcaster.createChannel(channelName, {
            isManualSubscription: true,
        })

        listenMethod(channel, channelHooks)

        return { ...returnValue, channel }
    }

    private listenProjectChatsChannelEvents(channel: BroadcasterChannel, eventHooks: Hooks): void {
        channel
            .listen(WSChannelEventEnum.ChatNew, (chat: Chat) => {
                eventHooks.onNewChat.trigger(chat, (getNotification = noop) => {
                    if (this.silentMode.value) {
                        return
                    }

                    this.sounds.newChat.play()

                    if (!this.shouldPushChatNotification(chat.id)) {
                        return
                    }

                    this.webNotify
                        .show({
                            title: getChatTitle(chat, this.lang),
                            body: getChatTitle(chat, this.lang) + ': ' + (chat.messages.at(-1)?.text || '...'),
                            data: { action: 'new-chat', chatId: chat.id },
                            vibrate: [ 100, 200 ],
                            requireInteraction: true,
                        })
                        .then(getNotification)
                })
            })
            .listen(WSChannelEventEnum.ChatAccept, (chat: Chat) => {
                eventHooks.onChatAccepted.trigger(chat, () => {
                    if (this.silentMode.value) {
                        return
                    }

                    this.sounds.newChatAccepted.play()
                })
            })
            .listen(WSChannelEventEnum.ChatReject, (chat: Chat) => {
                eventHooks.onChatRejected.trigger(chat, () => {
                    if (this.silentMode.value) {
                        return
                    }

                    this.sounds.newArchiveChat.play()
                })
            })
            .listen(WSChannelEventEnum.ChatFinish, (chat: Chat) => {
                eventHooks.onChatFinished.trigger(chat, () => {
                    if (this.silentMode.value) {
                        return
                    }

                    this.sounds.newArchiveChat.play()
                })
            })
            .listen(WSChannelEventEnum.ChatTransfer, (chat: Chat) => {
                eventHooks.onNewTransferredChat.trigger(chat, (getNotification = noop) => {
                    if (this.silentMode.value) {
                        return
                    }

                    this.sounds.newTransferredChat.play()

                    if (!this.shouldPushChatNotification(chat.id)) {
                        return
                    }

                    this.webNotify
                        .show({
                            title: this.lang.t('you-received-chat-from') + ' ' + getOperatorName(chat.operator),
                            body: getVisitorName(chat.visitor, this.lang) + ': ' + (chat.messages.at(-1)?.text || '...'),
                            data: { action: 'new-transferred-chat', chatId: chat.id },
                            vibrate: [ 100, 200 ],
                            requireInteraction: true,
                        })
                        .then(getNotification)
                })
            })
    }

    private listenChatChannelEvents(channel: BroadcasterChannel, eventHooks: Hooks): void {
        channel
            .listen(WSChannelEventEnum.ChatNewMessage, (message: ChatMessage) => {
                eventHooks.onNewMessage.trigger(message, (getNotification = noop) => {
                    if (this.silentMode.value) {
                        return
                    }

                    this.sounds.newMessage.play()

                    if (!this.shouldPushChatNotification(message.chat_id)) {
                        return
                    }

                    this.webNotify
                        .show({
                            tag: `chat:${ message.chat_id }:new-message`,
                            title: this.lang.t('new-message'),
                            body: message.text,
                            data: { action: 'new-message', chatId: message.chat_id },
                            vibrate: [ 200, 100, 200 ],
                            requireInteraction: true,
                            autoCloseAfter: 5000,
                        })
                        .then(getNotification)
                })
            })
            .listen(WSChannelEventEnum.ChatUpdateMessage, (message: ChatMessage) => {
                eventHooks.onMessageUpdate.trigger(message, noop)
            })
            .listen(WSChannelEventEnum.ChatDeleteMessage, (message: ChatMessage) => {
                eventHooks.onMessageDelete.trigger(message, noop)
            })
            .listen(WSChannelEventEnum.ChatReadMessage, (message: ChatMessage) => {
                eventHooks.onMessageRead.trigger(message, noop)
            })
    }

    private listenChatClientChannelEvents(channel: BroadcasterChannel, eventHooks: Hooks): void {
        channel
            .listen(WSClientChannelEventEnum.Typing, (payload) => {
                eventHooks.onTyping.trigger(payload, noop)
            })
    }

    private shouldPushChatNotification(id: Chat['id']): boolean {
        const route = this.router.currentRoute.value

        return !this.isWindowFocused.value
            || !String(route.name).startsWith('p-pid-chat')
            || (+route.params.cid !== id)
    }
}
