export const useSupported = (callback: () => unknown): ComputedRef<boolean> => {
    const isMounted = useMounted()

    return computed<boolean>(() => {
        // Для тригера
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        isMounted.value
        return Boolean(callback())
    })
}
