import { default as _91token_93Cgjt3xxEEDMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/change-password/[token].vue?macro=true";
import { default as _91token_93_46client13Z6EY1FTTMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/confirm/[token].client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/codebuild/output/src3920258916/src/spilky-client/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91token_93ArLMW2K5jTMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/create-password/[token].vue?macro=true";
import { default as _91token_93_46clientKsmCOZrFYcMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/invite/[token].client.vue?macro=true";
import { default as google_46clientV3itgvbPgSMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/oauth/google.client.vue?macro=true";
import { default as _91token_93f8Qzr9RkwzMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/reset-password/[token].vue?macro=true";
import { default as requestlST6zPk001Meta } from "/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/reset-password/request.vue?macro=true";
import { default as sign_45inVyG76czonjMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/sign-in.vue?macro=true";
import { default as indexV3QKmjjfS2Meta } from "/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/sign-up/index.vue?macro=true";
import { default as _1vxzXYEbwI3Meta } from "/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/sign-up/step/1.vue?macro=true";
import { default as _2WheYdXE8amMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/sign-up/step/2.vue?macro=true";
import { default as _3B3S0xCqDAZMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/sign-up/step/3.vue?macro=true";
import { default as _4enPfgrBBdDMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/sign-up/step/4.vue?macro=true";
import { default as stepLIhGIUMgwHMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/sign-up/step.vue?macro=true";
import { default as widget_45simulator_45_91token_93_46client7B5l7GgmZHMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/external/widget-simulator-[token].client.vue?macro=true";
import { default as index7bKQ8Iiaz7Meta } from "/codebuild/output/src3920258916/src/spilky-client/pages/index.vue?macro=true";
import { default as accountFYT5qHFInoMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/account.vue?macro=true";
import { default as _91id_93FM5rnN3jSuMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/billing/invoice/[id].vue?macro=true";
import { default as billing3KdvNUZZKhMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/billing.vue?macro=true";
import { default as _91cid_93zcJe6Q1w9IMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/chat/[kind]/[cid].vue?macro=true";
import { default as _91kind_931tL122aZaYMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/chat/[kind].vue?macro=true";
import { default as chat666GtBMpAHMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/chat.vue?macro=true";
import { default as inactive_45operatorVJY97Df4T6Meta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/inactive-operator.vue?macro=true";
import { default as knowledge_45basei3WAhEZzVBMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/knowledge-base.vue?macro=true";
import { default as accountwReFABZ82zMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/account.vue?macro=true";
import { default as basic_45settingsRy3TqkBdT1Meta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/basic-settings.vue?macro=true";
import { default as operatorsMaq4FuVJ6YMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/operators.vue?macro=true";
import { default as basic_45settings2Tlk18nvdDMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/basic-settings.vue?macro=true";
import { default as operatorsafJNC4JyyXMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/operators.vue?macro=true";
import { default as basic_45settingsr0IM1MfAChMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/basic-settings.vue?macro=true";
import { default as code_45script054sB3w3odMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/code-script.vue?macro=true";
import { default as contactsYbNNuR4LH9Meta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/contacts.vue?macro=true";
import { default as operators6D9ZZ5T4QbMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/operators.vue?macro=true";
import { default as personalizationITigJm1g2mMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/personalization.vue?macro=true";
import { default as configured7R1xYypJNMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure.vue?macro=true";
import { default as telegrammHY66Eo1yUMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/connect/telegram.vue?macro=true";
import { default as viberBCKSMgoYARMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/connect/viber.vue?macro=true";
import { default as channel6sNNEx4KXdMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel.vue?macro=true";
import { default as settingshAkNltAqD5Meta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/settings.vue?macro=true";
import { default as work_45analyticsVnkHe7HDAeMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-analytics.vue?macro=true";
import { default as work_45scheduleeCMqrYpsRzMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-schedule.vue?macro=true";
import { default as editY8BvEFcpF9Meta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit.vue?macro=true";
import { default as createWVO2sy33OQMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/operator/create.vue?macro=true";
import { default as operatorl60aOa01DcMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/operator.vue?macro=true";
import { default as logoLog1CQPF0sMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/logo.vue?macro=true";
import { default as settingsu7klxbLdoZMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/settings.vue?macro=true";
import { default as edit09refa2rcdMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit.vue?macro=true";
import { default as createfEq2TmRvgeMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/project/create.vue?macro=true";
import { default as projectr7yp3bXbbvMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/project.vue?macro=true";
import { default as basic_45settingsl3oTPFv5YYMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/basic-settings.vue?macro=true";
import { default as personalizationQpw0yYCCb8Meta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/personalization.vue?macro=true";
import { default as seo_45and_45analyticshARxGc9PvIMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/seo-and-analytics.vue?macro=true";
import { default as editoS27JEqZLPMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/quick-answer/[id]/edit.vue?macro=true";
import { default as createRGMiAJEGebMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/quick-answer/create.vue?macro=true";
import { default as quick_45answerz6mok0ehJUMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/quick-answer.vue?macro=true";
import { default as settingBJiSV1zfUfMeta } from "/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting.vue?macro=true";
export default [
  {
    name: "language-auth-change-password-token",
    path: "/:language?/auth/change-password/:token()",
    meta: _91token_93Cgjt3xxEEDMeta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/change-password/[token].vue")
  },
  {
    name: "language-auth-confirm-token",
    path: "/:language?/auth/confirm/:token()",
    meta: _91token_93_46client13Z6EY1FTTMeta || {},
    component: () => createClientPage(() => import("/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/confirm/[token].client.vue"))
  },
  {
    name: "language-auth-create-password-token",
    path: "/:language?/auth/create-password/:token()",
    meta: _91token_93ArLMW2K5jTMeta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/create-password/[token].vue")
  },
  {
    name: "language-auth-invite-token",
    path: "/:language?/auth/invite/:token()",
    meta: _91token_93_46clientKsmCOZrFYcMeta || {},
    component: () => createClientPage(() => import("/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/invite/[token].client.vue"))
  },
  {
    name: "language-auth-oauth-google",
    path: "/:language?/auth/oauth/google",
    meta: google_46clientV3itgvbPgSMeta || {},
    component: () => createClientPage(() => import("/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/oauth/google.client.vue"))
  },
  {
    name: "language-auth-reset-password-token",
    path: "/:language?/auth/reset-password/:token()",
    meta: _91token_93f8Qzr9RkwzMeta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/reset-password/[token].vue")
  },
  {
    name: "language-auth-reset-password-request",
    path: "/:language?/auth/reset-password/request",
    meta: requestlST6zPk001Meta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/reset-password/request.vue")
  },
  {
    name: "language-auth-sign-in",
    path: "/:language?/auth/sign-in",
    meta: sign_45inVyG76czonjMeta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/sign-in.vue")
  },
  {
    name: "language-auth-sign-up",
    path: "/:language?/auth/sign-up",
    meta: indexV3QKmjjfS2Meta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/sign-up/index.vue")
  },
  {
    name: "language-auth-sign-up-step",
    path: "/:language?/auth/sign-up/step",
    meta: stepLIhGIUMgwHMeta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/sign-up/step.vue"),
    children: [
  {
    name: "language-auth-sign-up-step-1",
    path: "1",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/sign-up/step/1.vue")
  },
  {
    name: "language-auth-sign-up-step-2",
    path: "2",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/sign-up/step/2.vue")
  },
  {
    name: "language-auth-sign-up-step-3",
    path: "3",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/sign-up/step/3.vue")
  },
  {
    name: "language-auth-sign-up-step-4",
    path: "4",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/[[language]]/auth/sign-up/step/4.vue")
  }
]
  },
  {
    name: "external-widget-simulator-token",
    path: "/external/widget-simulator-:token()",
    meta: widget_45simulator_45_91token_93_46client7B5l7GgmZHMeta || {},
    component: () => createClientPage(() => import("/codebuild/output/src3920258916/src/spilky-client/pages/external/widget-simulator-[token].client.vue"))
  },
  {
    name: "index",
    path: "/",
    meta: index7bKQ8Iiaz7Meta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/index.vue")
  },
  {
    name: "p-pid-account",
    path: "/p/:pid()/account",
    meta: accountFYT5qHFInoMeta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/account.vue")
  },
  {
    name: "p-pid-billing",
    path: "/p/:pid()/billing",
    meta: billing3KdvNUZZKhMeta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/billing.vue"),
    children: [
  {
    name: "p-pid-billing-invoice-id",
    path: "invoice/:id()",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/billing/invoice/[id].vue")
  }
]
  },
  {
    name: "p-pid-chat",
    path: "/p/:pid()/chat",
    meta: chat666GtBMpAHMeta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/chat.vue"),
    children: [
  {
    name: "p-pid-chat-kind",
    path: ":kind()",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/chat/[kind].vue"),
    children: [
  {
    name: "p-pid-chat-kind-cid",
    path: ":cid()",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/chat/[kind]/[cid].vue")
  }
]
  }
]
  },
  {
    name: "p-pid-inactive-operator",
    path: "/p/:pid()/inactive-operator",
    meta: inactive_45operatorVJY97Df4T6Meta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/inactive-operator.vue")
  },
  {
    name: "p-pid-knowledge-base",
    path: "/p/:pid()/knowledge-base",
    meta: knowledge_45basei3WAhEZzVBMeta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/knowledge-base.vue")
  },
  {
    name: "p-pid-setting",
    path: "/p/:pid()/setting",
    meta: settingBJiSV1zfUfMeta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting.vue"),
    children: [
  {
    name: "p-pid-setting-account",
    path: "account",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/account.vue")
  },
  {
    name: "p-pid-setting-channel",
    path: "channel",
    meta: channel6sNNEx4KXdMeta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel.vue"),
    children: [
  {
    name: "p-pid-setting-channel-id-configure",
    path: ":id()/configure",
    meta: configured7R1xYypJNMeta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure.vue"),
    children: [
  {
    name: "p-pid-setting-channel-id-configure-telegram-basic-settings",
    path: "telegram/basic-settings",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/basic-settings.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-telegram-operators",
    path: "telegram/operators",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/operators.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-viber-basic-settings",
    path: "viber/basic-settings",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/basic-settings.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-viber-operators",
    path: "viber/operators",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/operators.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-basic-settings",
    path: "widget/basic-settings",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/basic-settings.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-code-script",
    path: "widget/code-script",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/code-script.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-contacts",
    path: "widget/contacts",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/contacts.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-operators",
    path: "widget/operators",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/operators.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-personalization",
    path: "widget/personalization",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/personalization.vue")
  }
]
  },
  {
    name: "p-pid-setting-channel-connect-telegram",
    path: "connect/telegram",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/connect/telegram.vue")
  },
  {
    name: "p-pid-setting-channel-connect-viber",
    path: "connect/viber",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/channel/connect/viber.vue")
  }
]
  },
  {
    name: "p-pid-setting-operator",
    path: "operator",
    meta: operatorl60aOa01DcMeta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/operator.vue"),
    children: [
  {
    name: "p-pid-setting-operator-id-edit",
    path: ":id()/edit",
    meta: editY8BvEFcpF9Meta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit.vue"),
    children: [
  {
    name: "p-pid-setting-operator-id-edit-settings",
    path: "settings",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/settings.vue")
  },
  {
    name: "p-pid-setting-operator-id-edit-work-analytics",
    path: "work-analytics",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-analytics.vue")
  },
  {
    name: "p-pid-setting-operator-id-edit-work-schedule",
    path: "work-schedule",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-schedule.vue")
  }
]
  },
  {
    name: "p-pid-setting-operator-create",
    path: "create",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/operator/create.vue")
  }
]
  },
  {
    name: "p-pid-setting-project",
    path: "project",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/project.vue"),
    children: [
  {
    name: "p-pid-setting-project-id-edit",
    path: ":id()/edit",
    meta: edit09refa2rcdMeta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit.vue"),
    children: [
  {
    name: "p-pid-setting-project-id-edit-logo",
    path: "logo",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/logo.vue")
  },
  {
    name: "p-pid-setting-project-id-edit-settings",
    path: "settings",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/settings.vue")
  }
]
  },
  {
    name: "p-pid-setting-project-create",
    path: "create",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/project/create.vue")
  }
]
  },
  {
    name: "p-pid-setting-public-knowledge-base-basic-settings",
    path: "public-knowledge-base/basic-settings",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/basic-settings.vue")
  },
  {
    name: "p-pid-setting-public-knowledge-base-personalization",
    path: "public-knowledge-base/personalization",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/personalization.vue")
  },
  {
    name: "p-pid-setting-public-knowledge-base-seo-and-analytics",
    path: "public-knowledge-base/seo-and-analytics",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/seo-and-analytics.vue")
  },
  {
    name: "p-pid-setting-quick-answer",
    path: "quick-answer",
    meta: quick_45answerz6mok0ehJUMeta || {},
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/quick-answer.vue"),
    children: [
  {
    name: "p-pid-setting-quick-answer-id-edit",
    path: ":id()/edit",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/quick-answer/[id]/edit.vue")
  },
  {
    name: "p-pid-setting-quick-answer-create",
    path: "create",
    component: () => import("/codebuild/output/src3920258916/src/spilky-client/pages/p/[pid]/setting/quick-answer/create.vue")
  }
]
  }
]
  }
]