import type { $Fetch, NitroFetchRequest } from 'nitropack'
import type * as cardTypes from '~/ts/types/card'

export default <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
    all({ page = 1, perPage = undefined }: cardTypes.CardAll = {}) {
        return fetch<{ items: cardTypes.Card[] }>('/user/{userId}/card', {
            params: {
                page,
                'per-page': perPage,
            },
        })
    },
    add() {
        return fetch<{ html: string }>('/user/{userId}/card', {
            method: 'post',
        })
    },
    markAsPrimary({ id }: cardTypes.CardMarkAsPrimary) {
        return fetch<cardTypes.Card>(`/user/{userId}/card/${ id }/as-primary`, {
            method: 'post',
        })
    },
    delete({ id }: cardTypes.CardDelete) {
        return fetch(`/user/{userId}/card/${ id }`, {
            method: 'delete',
        })
    },
})
