import type { $Fetch, NitroFetchRequest } from 'nitropack'
import type { User } from '~/ts/types/user'

export default <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
    link({ token }: { token: string }) {
        return fetch<User['google']>('/google/link', {
            method: 'post',
            body: { token },
        })
    },
    unlink() {
        return fetch('/google/unlink', {
            method: 'post',
        })
    },
})
