import type { SearchParameters } from 'ofetch'
import type { ApiFetchOptions } from '~/ts/types/api'
import buildSearchParameters from '~/helpers/fetch/buildSearchParameters'

export default (options: ApiFetchOptions): SearchParameters | undefined => {
    if (!options.query) {
        return
    }

    return buildSearchParameters(options.query)
}
