import { WSServerMessageEventEnum } from '~/ts/enums/ws'
import type { UseLogExecutor } from '~/composables/useLog'
import type { Broadcaster } from '~/helpers/ws/Broadcaster'
import deserializeServerMessage from '~/helpers/ws/deserializeServerMessage'
import handleServerMessagePublish from '~/helpers/ws/handleServerMessagePublish'
import handleServerMessageError from '~/helpers/ws/handleServerMessageError'

export default (broadcaster: Broadcaster, log: UseLogExecutor, event: MessageEvent): void => {
    log(event.data, 'handleMessage')

    const message = deserializeServerMessage(event.data)

    switch (message.event) {
        case WSServerMessageEventEnum.Connect:
            broadcaster.socketId.value = message.connectionId || undefined
            break
        case WSServerMessageEventEnum.Subscribe:
        case WSServerMessageEventEnum.Unsubscribe:
            break
        case WSServerMessageEventEnum.Publish:
        case WSServerMessageEventEnum.PublishClient:
            handleServerMessagePublish(broadcaster, message)
            break
        case WSServerMessageEventEnum.Error:
            handleServerMessageError(message)
            break
        default:
            useLog(
                'Unknown event - ' + message.event,
                'handleMessage',
                { error: true },
            )
    }
}
