import type { $Fetch, NitroFetchRequest } from 'nitropack'
import type { Invoice } from '~/ts/types/invoice'
import type * as planTypes from '~/ts/types/plan'

export default <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
    all({ page = 1, perPage = undefined }: planTypes.PlanAll = {}) {
        return fetch<{ items: planTypes.Plan[] }>('/project/{projectId}/plan', {
            params: {
                page,
                'per-page': perPage,
            },
        })
    },
    purchase({ id, operatorQuantity }: planTypes.PlanPurchase) {
        return fetch<Invoice>(`/project/{projectId}/plan/${ id }/purchase`, {
            method: 'post',
            body: {
                quantity: operatorQuantity,
            },
        })
    },
})
