import { defineStore } from 'pinia'
import type { Language, LanguageLocale } from '~/ts/types/language'
import type { FormSelectOption } from '~/ts/types/form'

export const useLanguageStore = defineStore('languageStore', () => {
    const defaultLanguage: Language = 'uk'
    const supportedLocales: LanguageLocale[] = [
        {
            name: 'Українська',
            iso: 'uk',
            flag: '🇺🇦',
            nativeLocale: 'uk-UA',
        },
        {
            name: 'English',
            iso: 'en',
            flag: '🇺🇸',
            nativeLocale: 'en-US',
        },
        {
            name: 'Ру**кий',
            iso: 'ru',
            flag: '🏳️',
            nativeLocale: 'ru-RU',
        },
    ]
    const hooks = {
        onLanguageChange: createEventHook(),
    }
    const locale = ref<LanguageLocale>(supportedLocales.find(v => v.iso === defaultLanguage))

    const language = computed<Language>(() => locale.value.iso)
    const supportedLanguages = computed<Language[]>(() => supportedLocales.map(v => v.iso))
    const languageOptions = computed<FormSelectOption[]>(() => {
        return supportedLocales.map(v => ({ value: v.iso, text: v.name }))
    })
    const languageOptionsByValue = computed<Record<Language, FormSelectOption>>(() => {
        return languageOptions.value.reduce<any>((result, option) => {
            result[option.value] = option

            return result
        }, {})
    })
    const supportedLocalesByIso = computed<Record<Language, LanguageLocale>>(() => {
        return supportedLocales.reduce<any>((result, option) => {
            result[option.iso] = option

            return result
        }, {})
    })

    const languageOptionValues = computed<Language[]>(() => supportedLanguages.value)

    const isSupportedLanguage = (language: Language): boolean => supportedLanguages.value.includes(language)

    const setLanguage = (value: Language): void => {
        if (!isSupportedLanguage(value)) {
            value = defaultLanguage
        }

        if (language.value === value) {
            return
        }

        locale.value = supportedLocalesByIso.value[value]

        hooks.onLanguageChange.trigger()

        useAppCookie('language').value = value
    }

    return {
        defaultLanguage,
        supportedLocales,
        supportedLocalesByIso,
        language,
        locale,
        supportedLanguages,
        languageOptions,
        languageOptionsByValue,
        languageOptionValues,
        isSupportedLanguage,
        setLanguage,
        onLanguageChange: hooks.onLanguageChange.on,
    }
})
