import validate from "/codebuild/output/src3920258916/src/spilky-client/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/codebuild/output/src3920258916/src/spilky-client/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/codebuild/output/src3920258916/src/spilky-client/middleware/admin.ts"),
  auth: () => import("/codebuild/output/src3920258916/src/spilky-client/middleware/auth.ts"),
  chat: () => import("/codebuild/output/src3920258916/src/spilky-client/middleware/chat.ts"),
  guest: () => import("/codebuild/output/src3920258916/src/spilky-client/middleware/guest.ts")
}