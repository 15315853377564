import type { FetchContext } from 'ofetch'
import type { ApiContext } from '~/ts/types/api'
import refreshAuthToken from '~/helpers/fetch/refreshAuthToken'
import pushError from '~/helpers/fetch/pushError'

export default async (apiContext: ApiContext, fetchContext: FetchContext): Promise<void> => {
    const {
        options,
        response,
    } = fetchContext

    if (response.status === 401) {
        try {
            await apiContext.nuxtApp.runWithContext(async () => {
                await refreshAuthToken(options, apiContext)
            })
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            /* empty */
        }
    } else {
        await apiContext.nuxtApp.runWithContext(() => {
            pushError(fetchContext, 'onResponseError')
        })
    }
}
