import type { ApiContext, ApiFetchOptions } from '~/ts/types/api'
import refreshAuthToken from '~/helpers/fetch/refreshAuthToken'
import setRequestHeader from '~/helpers/fetch/setRequestHeader'
import updateAuthTokenFromServer from '~/helpers/fetch/updateAuthTokenFromServer'

export default async (options: ApiFetchOptions, apiContext: ApiContext): Promise<void> => {
    if (options.skipAuthTokenProcessing) {
        return
    }

    updateAuthTokenFromServer(apiContext)

    if (validateJWT(apiContext.token.value)) {
        setRequestHeader(options.headers, 'Authorization', `Bearer ${ apiContext.token.value }`)
    } else {
        await refreshAuthToken(options, apiContext)
    }
}
