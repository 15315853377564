<script
    lang="ts"
    setup
>
    type Props = {
        type?: 'submit' | 'reset' | 'button'
        secondary?: boolean
        underlined?: boolean
        large?: boolean
        medium?: boolean
        small?: boolean
        disabled?: boolean
        loading?: boolean
        active?: boolean
        wrapper?: boolean
        activeColor?: string
        inactiveColor?: string
        disableColor?: string
        activeBgColor?: string
        inactiveBgColor?: string
        hoverBgColor?: string
        disableBgColor?: string
        contentClass?: string
    }

    const props = withDefaults(defineProps<Props>(), {
        type: 'button',
        secondary: false,
        underlined: false,
        large: false,
        medium: false,
        small: false,
        disabled: false,
        loading: false,
        active: false,
        wrapper: false,
        activeColor: undefined,
        inactiveColor: undefined,
        disableColor: undefined,
        activeBgColor: undefined,
        inactiveBgColor: undefined,
        hoverBgColor: undefined,
        disableBgColor: undefined,
        contentClass: undefined,
    })

    const style = useCssModule()

    const buttonClass = computed<string[]>(() => {
        if (props.wrapper) {
            return [ style['button-wrapper'] ]
        }

        const classes = [ style['button'] ]

        if (props.underlined) {
            classes.push(style['underlined'])
        }

        if (props.small) {
            classes.push(style['small'])
        } else if (props.large) {
            classes.push(style['large'])
        } else {
            classes.push(style['medium'])
        }

        if (props.active) {
            classes.push(style['active'])
        }

        if (props.disabled) {
            classes.push(style['disabled'])
        }

        if (props.loading) {
            classes.push(style['loading'])
        }

        return classes
    })

    const loadingIconSize = computed<number>(() => {
        if (props.large) {
            return 24
        } else if (props.small) {
            return 16
        } else {
            return 20
        }
    })

    const activeColor = computed<string>(() => {
        if (props.activeColor) {
            return props.activeColor
        }

        if (props.underlined) {
            return '#8a8f9e'
        } else {
            return '#000'
        }
    })

    const inactiveColor = computed<string>(() => {
        if (props.inactiveColor) {
            return props.inactiveColor
        }

        return '#000'
    })

    const activeBgColor = computed<string>(() => {
        if (props.activeBgColor) {
            return props.activeBgColor
        }

        if (props.underlined) {
            return 'none'
        } else if (props.secondary) {
            return '#b5bccf'
        } else {
            return '#efb31a'
        }
    })

    const inactiveBgColor = computed<string>(() => {
        if (props.inactiveBgColor) {
            return props.inactiveBgColor
        }

        if (props.underlined) {
            return 'none'
        } else if (props.secondary) {
            return '#e3e5eb'
        } else {
            return 'var(--color-primary)'
        }
    })

    const hoverBgColor = computed<string>(() => {
        if (props.hoverBgColor) {
            return props.hoverBgColor
        }

        if (props.underlined) {
            return 'none'
        } else if (props.secondary) {
            return '#ced2dc'
        } else {
            return '#efcc1a'
        }
    })

    const disableBgColor = computed<string>(() => {
        if (props.disableBgColor) {
            return props.disableBgColor
        }

        if (props.underlined) {
            return 'none'
        } else if (props.secondary) {
            return '#f5f5f5'
        } else {
            return '#fff5c6'
        }
    })

    const disableColor = computed<string>(() => {
        if (props.disableColor) {
            return props.disableColor
        }

        return '#a7a7a7'
    })
</script>

<template>
    <button
        :type="props.type"
        :class="buttonClass"
        :disabled="props.disabled || props.loading"
        :data-active="props.active || undefined"
    >
        <template v-if="props.wrapper">
            <slot />
        </template>

        <span
            v-else
            key="button-content"
            :class="$style['button-content']"
        >
            <AppIconLoading
                v-if="props.loading"
                key="loading"
                :class="$style['button-content-loading-icon']"
                :size="loadingIconSize"
                :color="activeColor"
                :animate="true"
            />

            <span
                key="content"
                :class="[ $style['button-content-section'], props.contentClass ]"
            >
                <slot />
            </span>
        </span>

        <span
            v-if="props.underlined"
            key="line"
            :class="$style['underlined-line']"
        ></span>
    </button>
</template>

<style
    lang="sass"
    module
    scoped
>
    .button-wrapper
        appearance: none
        padding: 0
        background: none
        outline: none
        border: none
        text-align: inherit
        cursor: pointer

    .button
        appearance: none
        white-space: nowrap
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        vertical-align: middle
        font: inherit
        font-style: normal
        font-weight: 500
        text-align: center
        outline: none
        border: none
        background: none
        user-select: none
        transition: background-color var(--transition-default-duration-with-ease)
        will-change: background-color
        cursor: pointer

        &:not(.underlined)
            background: v-bind('inactiveBgColor')

        &:not(.disabled):not(.loading)
            &:not(.underlined)
                &:hover
                    background: v-bind('hoverBgColor')

                &:active,
                &.active
                    background: v-bind('activeBgColor')

            &:hover,
            &:active,
            &.active
                .button-content
                    color: v-bind('activeColor')

                    + .underlined-line
                        background: v-bind('activeColor')

        &.loading
            &:not(.underlined)
                background: v-bind('activeBgColor')

            .button-content-section
                opacity: 0

            > .underlined-line
                background: none
                transition: none

        &.disabled
            cursor: not-allowed

            &:not(.underlined)
                background: v-bind('disableBgColor')

            .button-content
                color: v-bind('disableColor')

            .underlined-line
                background: v-bind('disableColor')

        > .button-content
            position: relative
            color: v-bind('inactiveColor')
            transition: color var(--transition-default-duration-with-ease)
            will-change: color

            &,
            .button-content-section
                display: flex
                align-items: center
                justify-content: center

        .button-content-loading-icon
            position: absolute

        .underlined-line
            width: 100%
            height: 1px
            background-color: v-bind('inactiveColor')
            transition: background-color var(--transition-default-duration-with-ease)
            will-change: background-color

        &.large
            font-size: 14px
            line-height: 22px
            letter-spacing: 0.03em
            padding: 16px 24px

            &:not(.underlined)
                border-radius: 8px

        &.medium
            font-size: 13px
            line-height: 20px
            letter-spacing: 0.02em
            padding: 10px 24px

            &:not(.underlined)
                border-radius: 8px

        &.small
            font-size: 12px
            line-height: 18px
            letter-spacing: 0.01em
            padding: 4px 8px

            &:not(.underlined)
                border-radius: 23px
</style>
