import type { AnyFn } from '~/ts/types/common'

type UseNetwork = {
    isOnline: Ref<boolean>
    wasOffline: Ref<boolean>
    onOnline: (fn: AnyFn) => VoidFunction
    onOffline: (fn: AnyFn) => VoidFunction
}

type UseNetworkOptions = {
    listen?: boolean
}

export const useNetwork = (options: UseNetworkOptions = {}): UseNetwork => {
    const {
        listen = true,
    } = options

    const { window } = getClientContext()

    const isOnline = ref<boolean>(true)
    const wasOffline = ref<boolean>(false)

    if (!window) {
        return {
            isOnline,
            wasOffline,
            onOnline: () => noop,
            onOffline: () => noop,
        }
    }

    const hooks = {
        onOnline: createEventHook(),
        onOffline: createEventHook(),
    }

    const handler = (): void => {
        isOnline.value = window.navigator?.onLine ?? true

        if (!isOnline.value) {
            wasOffline.value = true
        }

        if (listen) {
            hooks[isOnline.value ? 'onOnline' : 'onOffline'].trigger()
        }
    }

    handler()

    if (listen) {
        onEvent(window, 'online', handler)
        onEvent(window, 'offline', handler)
    }

    return {
        isOnline,
        wasOffline,
        onOnline: hooks.onOnline.on,
        onOffline: hooks.onOffline.on,
    }
}
