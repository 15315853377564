export const isNetworkError = (error: any): boolean => {
    const errorMessages = [
        'Failed to fetch', // Chrome
        'NetworkError when attempting to fetch resource.', // Firefox
        'The Internet connection appears to be offline.', // Safari 16
        'Load failed', // Safari 17+
        'Network request failed', // `cross-fetch`
        'fetch failed', // Undici (Node.js)
    ]

    const isValid = isObject(error)
        && (error.name === 'TypeError')
        && isString(error.message)

    if (!isValid) {
        return false
    }

    // We do an extra check for Safari 17+ as it has a very generic error message.
    // Network errors in Safari have no stack.
    if (error.message === 'Load failed') {
        return isUndefined(error.stack)
    }

    return errorMessages.includes(error.message)
}
