import AuthService from '~/services/auth.service'
import UserService from '~/services/user.service'
import ProjectService from '~/services/project.service'
import OperatorService from '~/services/operator.service'
import QuickAnswerService from '~/services/quick-answer.service'
import SettingService from '~/services/setting.service'
import KnowledgeBaseService from '~/services/knowledge-base.service'
import CommunicationChannelService from '~/services/communication-channel.service'
import ChatService from '~/services/chat.service'
import VisitorService from '~/services/visitor.service'
import PlanService from '~/services/plan.service'
import SubscriptionService from '~/services/subscription.service'
import InvoiceService from '~/services/invoice.service'
import CardService from '~/services/card.service'
import GoogleService from '~/services/google.service'

export const useApi = () => {
    const { $api } = useNuxtApp()

    return {
        auth: AuthService($api),
        user: UserService($api),
        project: ProjectService($api),
        operator: OperatorService($api),
        quickAnswer: QuickAnswerService($api),
        setting: SettingService($api),
        knowledgeBase: KnowledgeBaseService($api),
        communicationChannel: CommunicationChannelService($api),
        chat: ChatService($api),
        visitor: VisitorService($api),
        plan: PlanService($api),
        subscription: SubscriptionService($api),
        invoice: InvoiceService($api),
        card: CardService($api),
        google: GoogleService($api),
    }
}
