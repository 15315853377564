import type { AuthHandshakeReturnValue } from '~/ts/types/auth'
import type { User } from '~/ts/types/user'
import type { Project } from '~/ts/types/project'
import type { Operator } from '~/ts/types/operator'

export default (data: AuthHandshakeReturnValue): {
    user: User
    projects: Project[]
    operators: Operator[]
    handshakeUnread: AuthHandshakeReturnValue['unread']
} => {
    const userProjects = []
    const userOperators = []

    for (const { project, ...operatorData } of data.operators) {
        delete operatorData.user

        userProjects.push(project)
        userOperators.push(operatorData)
    }

    return {
        user: data.user,
        projects: userProjects,
        operators: userOperators,
        handshakeUnread: data.unread,
    }
}
