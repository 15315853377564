import { Broadcaster } from '~/helpers/ws/Broadcaster'

type UseBroadcasterExtra = {
    isConnected: ComputedRef<boolean>
    isConnecting: ComputedRef<boolean>
    wasDisconnected: ComputedRef<boolean>
    socketId: ComputedRef<string>
}

export type UseBroadcaster = Omit<Broadcaster, 'ws' | 'isConnected' | 'isConnecting' | 'wasDisconnected' | 'socketId'> & UseBroadcasterExtra

export const broadcasterInstance = ref<Broadcaster | undefined>()

export const useBroadcaster = (): UseBroadcaster => {
    if (!broadcasterInstance.value) {
        broadcasterInstance.value = new Broadcaster()
    }

    // Якщо використовувати refs з екземпляра бродкастера, то вони втрачають реактивність в шаблоні
    const isConnected = computed<boolean>(() => broadcasterInstance.value.isConnected as any)
    const isConnecting = computed<boolean>(() => broadcasterInstance.value.isConnecting as any)
    const wasDisconnected = computed<boolean>(() => broadcasterInstance.value.wasDisconnected as any)
    const socketId = computed<string>(() => broadcasterInstance.value.socketId as any)

    return {
        ...broadcasterInstance.value,
        isConnected,
        isConnecting,
        wasDisconnected,
        socketId,
    }
}
