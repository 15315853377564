import type { FetchContext } from 'ofetch'
import { useUserStore } from '~/stores/user'

export default (fetchContext: FetchContext): void => {
    let _userStore: ReturnType<typeof useUserStore>

    const getUserStore = (): typeof _userStore => {
        if (!_userStore) {
            _userStore = useUserStore()
        }

        return _userStore
    }

    if (isString(fetchContext.request)) {
        if (fetchContext.request.includes('{projectId}')) {
            fetchContext.request = fetchContext.request.replace('{projectId}', String(getUserStore().currentProject.id))
        }

        if (fetchContext.request.includes('{operatorId}')) {
            fetchContext.request = fetchContext.request.replace('{operatorId}', String(getUserStore().currentOperator.id))
        }

        if (fetchContext.request.includes('{userId}')) {
            fetchContext.request = fetchContext.request.replace('{userId}', String(getUserStore().user.id))
        }
    }
}
