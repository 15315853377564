import type { WritableComputedRef } from 'vue'
import type { AnyFn } from '~/ts/types/common'

export type AnimateContext = {
    timing?: (timeFraction: number) => number
    draw: (progress: number) => void
    duration?: number
    onComplete?: AnyFn
}

export type AnimateTextWritingOptions = {
    initialValue?: string
    duration?: number
    onComplete?: AnyFn
}

export const animationTiming = {
    defaultDuration: 0.26,
    defaultTiming: 'linear',
    linear(timeFraction: number): number {
        return timeFraction
    },
    quad(timeFraction: number): number {
        return Math.pow(timeFraction, 2)
    },
    circ(timeFraction: number): number {
        return 1 - Math.sin(Math.acos(timeFraction))
    },
    makeEaseOut(timing: AnimateContext['timing']): AnimateContext['timing'] {
        return timeFraction => (1 - timing(1 - timeFraction)) || 0
    },
    makeEaseInOut(timing: AnimateContext['timing']): AnimateContext['timing'] {
        return (timeFraction: number): number => {
            if (timeFraction < 0.5) {
                return timing(2 * timeFraction) / 2
            } else {
                return (2 - timing(2 * (1 - timeFraction))) / 2
            }
        }
    },
}

export const animate = ({ timing, draw, duration, onComplete }: AnimateContext): void => {
    if (!import.meta.client) {
        return
    }

    const start = performance.now()

    if (!duration) {
        duration = animationTiming.defaultDuration
    }

    duration *= 1000

    const handle = (time): void => {
        let timeFraction = (time - start) / duration

        if (timeFraction < 0) {
            timeFraction = 0
        }

        if (timeFraction > 1) {
            timeFraction = 1
        }

        const progress = (timing || animationTiming[animationTiming.defaultTiming])(timeFraction)

        draw(progress)

        if (timeFraction < 1) {
            requestAnimationFrame(handle)
        } else {
            setTimeout(() => onComplete?.(), 0)
        }
    }

    requestAnimationFrame(handle)
}

export const animateTextWriting = (
    target: Ref<string> | WritableComputedRef<string>,
    options: AnimateTextWritingOptions = {},
): (text: string) => void => {
    const {
        initialValue = '',
        duration = 30,
        onComplete = undefined,
    } = options

    const getStartIndex = (a: string, b: string): number => {
        let startIndex = 0

        for (let i = 0; i < a.length && i < b.length; i++) {
            if (a[i] !== b[i]) {
                break
            }

            startIndex++
        }

        return startIndex
    }

    let writingTimer: ReturnType<typeof setTimeout>

    const updateTarget = (textValue: string) => {
        if (!import.meta.client) {
            return
        }

        clearTimeout(writingTimer)

        if (!textValue) {
            target.value = ''

            onComplete?.()

            return
        }

        let index = getStartIndex(target.value, textValue)

        target.value = target.value.slice(0, index)

        const write = (): void => {
            if (index >= textValue.length) {
                onComplete?.()

                return
            }

            target.value += textValue.charAt(index)

            index++

            writingTimer = setTimeout(write, duration)
        }

        write()
    }

    if (initialValue) {
        updateTarget(initialValue)
    }

    return updateTarget
}
