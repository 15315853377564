import type { FetchContext } from 'ofetch'
import type { ApiContext } from '~/ts/types/api'

export default async (apiContext: ApiContext, fetchContext: FetchContext): Promise<void> => {
    await apiContext.nuxtApp.runWithContext(() => {
        useLog({
            request: fetchContext.request,
            data: fetchContext.response._data,
        }, 'onResponse', { devOnly: true, hint: true })
    })
}
