import type { $Fetch, NitroFetchRequest } from 'nitropack'
import type { AppTablePagination } from '~/ts/types/app'
import type * as projectTypes from '~/ts/types/project'

export default <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
    all({ page, perPage = APP_DEFAULT_PER_PAGE, expand }: projectTypes.ProjectAll) {
        return fetch<{ items: projectTypes.Project[], _meta: AppTablePagination }>('/project', {
            params: {
                page,
                'per-page': perPage,
                'expand': getFilledKeysString(expand),
            },
        })
    },
    one({ id }: projectTypes.ProjectOne) {
        return fetch<projectTypes.Project>(`/project/${ id }`, {
            params: { id },
        })
    },
    create(data: projectTypes.ProjectCreate) {
        return fetch<projectTypes.Project>('/project', {
            method: 'post',
            body: data,
        })
    },
    update({ id, data }: projectTypes.ProjectUpdate) {
        return fetch<projectTypes.Project>('/project/' + id, {
            method: 'put',
            body: data,
        })
    },
    updateLogo({ id, data }: projectTypes.ProjectUpdateLogo) {
        return fetch<{ file: projectTypes.Project['logo'] }>(`/project/${ id }/logo`, {
            method: 'post',
            body: data,
        })
    },
    deleteLogo({ id }: projectTypes.ProjectDeleteLogo) {
        return fetch(`/project/${ id }/logo`, {
            method: 'delete',
        })
    },
    delete({ id }: projectTypes.ProjectDelete) {
        return fetch('/project/' + id, {
            method: 'delete',
        })
    },
})
