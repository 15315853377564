export enum WSMessageActionEnum {
    Subscribe = 'subscribe',
    Unsubscribe = 'unsubscribe',
    PublishClient = 'publish-client',
}

export enum WSServerMessageEventEnum {
    Connect = 'internal:connect',
    Subscribe = 'internal:subscribe',
    Unsubscribe = 'internal:unsubscribe',
    Publish = 'internal:publish',
    PublishClient = 'internal:publish-client',
    Error = 'internal:error',
}

export enum WSChannelEventEnum {
    ChatNew = 'ChatNew',
    ChatAccept = 'ChatAccept',
    ChatFinish = 'ChatFinish',
    ChatReject = 'ChatReject',
    ChatTransfer = 'ChatTransfer',
    ChatNewMessage = 'ChatNewMessage',
    ChatUpdateMessage = 'ChatUpdateMessage',
    ChatDeleteMessage = 'ChatDeleteMessage',
    ChatReadMessage = 'ChatReadMessage',
}

export enum WSClientChannelEventEnum {
    Typing = 'Typing',
}
