import type { ChatTypingContext } from '~/ts/types/chat'

export class TypingHandler {
    private handlerPrintingPending = false
    private visitorTypingPending = false
    private autoStopTimer = useTimer(() => {
        // Якщо не отримано команди зупинки друку від відвідувача (через розрив зʼєднання тощо)
        if (this.visitorTypingPending) {
            this.stop('visitor')
        }
    }, 4000)

    constructor(
        private typingContext: ChatTypingContext,
    ) {
    }

    private update = animateTextWriting(
        computed({
            get: () => this.typingContext.text,
            set: v => (this.typingContext.text = v),
        }),
        {
            duration: 30,
            onComplete: () => {
                setTimeout(() => this.stop('handler'), 500)
            },
        },
    )

    public write(text: string): void {
        this.handlerPrintingPending = true
        this.visitorTypingPending = true

        if (!this.typingContext.typing) {
            this.typingContext.typing = true
        }

        this.update(text)

        this.autoStopTimer.start()
    }

    public stop(by: 'handler' | 'visitor'): void {
        if (by === 'handler') {
            this.handlerPrintingPending = false
        } else {
            this.visitorTypingPending = false
        }

        if (this.handlerPrintingPending || this.visitorTypingPending) {
            return
        }

        this.typingContext.typing = false
    }
}
