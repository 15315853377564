import type { ApiFetchOptions } from '~/ts/types/api'

export default (headers: ApiFetchOptions['headers'], name: string, value: string): void => {
    if (isEmpty(value, { primitive: true })) {
        return
    }

    if (Array.isArray(headers)) {
        headers.push([ name, value ])
    } else if (headers instanceof Headers) {
        headers.set(name, value)
    } else {
        headers[name] = value
    }
}
