import type { ApiContext, ApiFetchOptions } from '~/ts/types/api'
import buildQuery from '~/helpers/fetch/buildQuery'
import setAuthToken from '~/helpers/fetch/setAuthToken'
import setRequestHeader from '~/helpers/fetch/setRequestHeader'

export default async (options: ApiFetchOptions, apiContext: ApiContext): Promise<void> => {
    const headers = options.headers ||= {}

    setRequestHeader(headers, 'X-Socket-ID', apiContext.broadcaster.socketId.value)
    setRequestHeader(headers, 'Accept-Language', apiContext.lang.language.value)

    await setAuthToken(options, apiContext)

    options.query = buildQuery(options)
}
