import type { ApiContext, ApiFetchOptions } from '~/ts/types/api'
import setRequestHeader from '~/helpers/fetch/setRequestHeader'

export default async (options: ApiFetchOptions, apiContext: ApiContext): Promise<boolean> => {
    const refreshToken = useAppCookie<string>('refreshToken')

    if (!refreshToken.value) {
        if (!options.preventLogout) {
            await apiContext.userStore.logout()
        }

        return false
    }

    const refreshTokenLock = useState('refreshTokenLock', () => {
        return shallowRef(
            createLock(() => {
                return useApi().auth.refreshToken({
                    refreshToken: refreshToken.value,
                })
            }),
        )
    })

    try {
        const data = await refreshTokenLock.value.request()

        apiContext.token.value = data['access_token']
        refreshToken.value = data['refresh_token']
    } catch (error) {
        if ([ 403, 404 ].includes(error.value.statusCode)) {
            if (!options.preventLogout) {
                await apiContext.userStore.logout()
            }
        }

        return false
    }

    setRequestHeader(options.headers, 'Authorization', `Bearer ${ apiContext.token.value }`)

    return true
}
